import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

export const TopHeadingSpace = () => {
  return <Box sx={{ height: '50px' }}></Box>;
};

const TopHeading = ({ icon, title }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      sx={{
        position: 'sticky',
        top: 10,
        backgroundColor: '#fff',
        zIndex: 100,
        pb: 1,
      }}
    >
      {icon}
      <Typography variant="h1">{title}</Typography>
    </Stack>
  );
};

export default TopHeading;
