import React from 'react'

const UserWithBackground = (props) => {
  return (
    <svg 
    width={ props.size || 30 } 
    height={ props.size || 30 } 
    viewBox="0 0 24 24"
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    >
        <path d="M22 7.81V16.19C22 19 20.71 20.93 18.44 21.66C17.78 21.89 17.02 22 16.19 22H7.81C6.98 22 6.22 21.89 5.56 21.66C3.29 20.93 2 19 2 16.19V7.81C2 4.17 4.17 2 7.81 2H16.19C19.83 2 22 4.17 22 7.81Z" fill={props?.color || '#F2F2F2'}/>
        <path d="M18.4396 21.6597C17.7796 21.8897 17.0196 21.9998 16.1896 21.9998H7.80957C6.97957 21.9998 6.21957 21.8897 5.55957 21.6597C5.90957 19.0197 8.66957 16.9697 11.9996 16.9697C15.3296 16.9697 18.0896 19.0197 18.4396 21.6597Z" fill="#010101"/>
        <path d="M15.5799 11.58C15.5799 13.56 13.9799 15.17 11.9999 15.17C10.0199 15.17 8.41992 13.56 8.41992 11.58C8.41992 9.60002 10.0199 8 11.9999 8C13.9799 8 15.5799 9.60002 15.5799 11.58Z" fill="#010101"/>
    </svg>
  );
}

export default UserWithBackground;