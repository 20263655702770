import React from 'react';
import { Box, Typography } from '@mui/material';

const VideoStats = ({ data, userCount }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-evenly"
      backgroundColor={(theme) => theme.palette.primary.main}
      p={3}
      borderRadius="12px"
      color="white"
    >
      <Box flexGrow="1" textAlign="center" borderRight="1px solid white">
        <Typography variant="h6" color="#F2F2F2" pb="5px">
        No. of Sign Ups
        </Typography>
        <Typography variant="h1_bold">{userCount || 0}</Typography>
      </Box>
      <Box flexGrow="1" textAlign="center" borderRight="1px solid white">
        <Typography variant="h6" color="#F2F2F2" pb="5px">
          No. of Videos Created
        </Typography>
        <Typography variant="h1_bold">{data?.totalCount || 0}</Typography>
      </Box>
      <Box flexGrow="1" textAlign="center" borderRight="1px solid white">
        <Typography variant="h6" color="#F2F2F2" pb="5px">
          Total Views on Videos
        </Typography>
        <Typography variant="h1_bold">{data?.totalViewers || 0}</Typography>
      </Box>
      <Box flexGrow="1" textAlign="center">
        <Typography variant="h6" color="#F2F2F2" pb="5px">
          Average Views per Video
        </Typography>
        <Typography variant="h1_bold">
          {Math.round(data?.averageViewers) || 0}
        </Typography>
      </Box>
    </Box>
  );
};

export default VideoStats;
