import * as React from 'react';

function SearchIcon(props) {
  return (
    <svg
      width={props.size || 16}
      height={props.size || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
        <path d="M1.33301 7.66634C1.33301 4.16634 4.16634 1.33301 7.66634 1.33301" stroke="#797979" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.9995 7.66699C13.9995 11.167 11.1662 14.0003 7.66616 14.0003C5.17283 14.0003 3.01283 12.5603 1.97949 10.4603" stroke="#797979" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.33301 3.33301H13.333" stroke="#797979" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M9.33301 5.33301H11.333" stroke="#797979" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.6663 14.6663L13.333 13.333" stroke="#797979" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default SearchIcon;
