import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
  labels: [],
  datasets: [
    {
      label: '',
      data: [],
      backgroundColor: [],
      borderWidth: 1,
      cutout: '80%',
      circumference: 180,
      rotation: 270,
    },
  ],
};

const options = {
    plugins: {
        legend: {
            display: false,
        },
    },
    aspectRatio: 1.5,
};

const DonutChart = ({ label, labels, value, backgroundColor }) => {
  data.labels = labels;
  data.datasets[0].label = label;
  data.datasets[0].data = value;
  data.datasets[0].backgroundColor = backgroundColor;

  return <Doughnut data={data} options={options} />;
}

export default DonutChart;