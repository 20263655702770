import React, { useCallback, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '../../assets/icons/search';
import debounce from 'lodash.debounce';

const CustomSearch = ({ onSearch, value, placeholder="Search..." }) => {
  const [searchTerm, setSearchTerm] = React.useState(value);

  // Create a debounced function using useCallback to ensure it is not recreated on every render
  const debouncedSearch = useCallback(
    debounce((value) => {
      onSearch(value);
    }, 700), // debounce delay
    []
  );

  useEffect(() => {
    // Call the debounced function whenever query changes
    debouncedSearch(searchTerm);
  }, [searchTerm, debouncedSearch]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <TextField
      variant="outlined"
      placeholder={placeholder}
      value={searchTerm}
      onChange={handleSearchChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx = {{
          width: '250px !important',
          '& .MuiOutlinedInput-root': {
            pl: '5px'
          },
          '& .MuiOutlinedInput-input': {
            padding: '10px 10px 10px 5px !important',
            '& .MuiInputAdornment-root': {
              paddingRight: 0
            }
        },
    }}
    />
  )
}

export default CustomSearch