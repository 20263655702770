import * as React from 'react';

function WithdrawalIcon(props) {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={5.5}
        cy={18.5}
        r={4.5}
        fill="#FEE06C"
        fillOpacity={props.variant === 'light' ? 1 : 0.5}
      />
      <path
        d="M19.48 12.95h2.02v-1.44c0-2.07-1.69-3.76-3.76-3.76H6.26c-2.07 0-3.76 1.69-3.76 3.76v3.65c.79-.72 1.85-1.16 3-1.16a4.5 4.5 0 014.5 4.5c0 .53-.09 1.03-.26 1.5-.27.79-.77 1.47-1.4 2h9.4c2.07 0 3.76-1.69 3.76-3.76v-1.19h-1.9c-1.08 0-2.07-.79-2.16-1.87-.06-.63.18-1.22.6-1.63.37-.38.88-.6 1.44-.6z"
        fill="#FEE06C"
      />
      <path
        d="M5.5 14c-1.15 0-2.21.44-3 1.16A4.428 4.428 0 001 18.5 4.5 4.5 0 005.5 23c1.08 0 2.06-.38 2.84-1 .63-.53 1.13-1.21 1.4-2 .17-.47.26-.97.26-1.5A4.5 4.5 0 005.5 14zm2.7 3.63c0 .31-.25.55-.55.55-.3 0-.55-.24-.55-.55v-.11c0-.12-.09-.22-.21-.22H4.63a.547.547 0 01-.48.81c-.14 0-.28-.06-.38-.16l-.81-.81a.567.567 0 01-.12-.18.607.607 0 010-.42c.03-.06.07-.13.12-.18l.81-.8c.21-.22.56-.22.77 0 .18.17.2.44.09.64h2.26c.72 0 1.31.59 1.31 1.32v.11zM6.85 21.6c-.15 0-.29-.05-.39-.16a.532.532 0 01-.09-.64H4.11c-.72 0-1.31-.59-1.31-1.32v-.11c0-.31.25-.55.55-.55.3 0 .55.24.55.55v.11c0 .12.09.22.21.22h2.26a.547.547 0 01.09-.65.55.55 0 01.77 0l.81.81c.05.05.09.11.12.18.05.13.05.28 0 .42-.03.06-.07.13-.12.18l-.81.8c-.1.11-.24.16-.38.16zM14.85 3.95v3.8H6.26c-2.07 0-3.76 1.69-3.76 3.76V7.84c0-1.19.73-2.25 1.84-2.67l7.94-3c1.24-.46 2.57.45 2.57 1.78zM22.56 13.97v2.06c0 .55-.44 1-1 1.02H19.6c-1.08 0-2.07-.79-2.16-1.87-.06-.63.18-1.22.6-1.63.37-.38.88-.6 1.44-.6h2.08c.56.02 1 .47 1 1.02zM14 12.75H7c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h7c.41 0 .75.34.75.75s-.34.75-.75.75z"
        fill={props.variant === 'light' ? '#fff' : '#FEE06C'}
      />
    </svg>
  );
}

export default WithdrawalIcon;
