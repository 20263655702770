import React from 'react'
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import UserWithBackground from '../../assets/icons/userwithbackground';
import useGetUser from '../../hooks/useGetUser';
import Loading from '../../components/Loading/Loading';
import { formatDateAndTimeTimeZone } from '../../utils';
import Button from '@mui/material/Button';
import { Chip } from '@mui/material';
import TransactionIcon from '../../assets/icons/TransactionIcon';

const TransactionOverview = ({ open, data, handleClose }) => { console.log(data)
    const styles = {
        box: {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          rowGap: '25px',
          columnGap: '16px',
          pt: 2, 
          pb: 6, 
        },
        item: {
          flexBasis: 'calc(33.333% - 16px)', // Adjust width to be 1/3 minus the gap
          boxSizing: 'border-box',
        }
      };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth={true}
            maxWidth='md'
        >
            <DialogTitle sx={{ m: 0, p: 2, display: 'flex', alignItems: 'center', gap: 2 }} id="customized-dialog-title">
                <TransactionIcon size={35} />
                <Typography variant="h2">Transaction Overview</Typography>
            </DialogTitle>
            <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
            <DialogContent>
                { data?.user && 
                    <>
                        <Typography variant='h4'>User Details</Typography>
                        <Box sx={styles.box}>
                            <Box sx={styles.item}>
                                <Typography variant='h5_semibold' gutterBottom>Name</Typography>
                                <Card sx={{ pl: 2, pr: 2  }}>{data.user.name}</Card>
                            </Box>
                            <Box sx={styles.item}>
                                <Typography variant='h5_semibold' gutterBottom>Email</Typography>
                                <Card sx={{ pl: 2, pr: 2  }}>{data.user.email}</Card>
                            </Box>
                        </Box>
                    </>
                }
                <Typography variant='h4'>Transaction Details</Typography>
                <Box sx={styles.box}>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Txn ID</Typography>
                        <Card sx={{ pl: 2, pr: 2  }}>{data._id}</Card>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Type</Typography>
                        <Card sx={{ pl: 2, pr: 2  }}><Chip size='small' label={data.type} color="primary" variant="filled" /></Card>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom> Transaction Type</Typography>
                        <Card sx={{ pl: 2, pr: 2  }}><Chip size='small' label={data.txnType} color="secondary" variant="filled" /></Card>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Date and Time</Typography>
                        <Card sx={{ pl: 2, pr: 2  }}>{formatDateAndTimeTimeZone(data.createdAt)}</Card>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Amount</Typography>
                        <Card sx={{ pl: 2, pr: 2  }}>$ {data.amount}</Card>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Status</Typography>
                        <Card sx={{ pl: 2, pr: 2  }}><Chip size='small' label={data.status?.toUpperCase()} color={
                    (data.status === 'succeeded' || data.status === 'completed') ? 'success' :
                        (data.status === 'rejected' || data.status === 'canceled') ? 'error' : 'info'} variant="filled" /></Card>
                    </Box>
                </Box>
                {/* TASK */}
                {
                    data?.task && 
                    <>
                        <Typography variant='h4'>Stream Details</Typography>
                        <Box sx={styles.box}>
                            <Box sx={styles.item}>
                                <Typography variant='h5_semibold' gutterBottom>Task ID</Typography>
                                <Card sx={{ pl: 2, pr: 2  }}>{data.task._id}</Card>
                            </Box>
                            <Box sx={styles.item}>
                                <Typography variant='h5_semibold' gutterBottom>Title</Typography>
                                <Card sx={{ pl: 2, pr: 2  }}>{data.task.title}</Card>
                            </Box>
                            <Box sx={styles.item}>
                                <Typography variant='h5_semibold' gutterBottom>Description</Typography>
                                <Card sx={{ pl: 2, pr: 2  }}>{data.task.description}</Card>
                            </Box>
                            <Box sx={styles.item}>
                                <Typography variant='h5_semibold' gutterBottom>Type</Typography>
                                <Card sx={{ pl: 2, pr: 2  }}><Chip size='small' label={data.type} color="primary" variant="filled" /></Card>
                            </Box>
                            <Box sx={styles.item}>
                                <Typography variant='h5_semibold' gutterBottom>Date and Time</Typography>
                                <Card sx={{ pl: 2, pr: 2  }}>{formatDateAndTimeTimeZone(data.createdAt)}</Card>
                            </Box>
                            <Box sx={styles.item}>
                                <Typography variant='h5_semibold' gutterBottom>Amount</Typography>
                                <Card sx={{ pl: 2, pr: 2  }}>$ {data.amount}</Card>
                            </Box>
                        </Box>
                    </>

                }
                {/* PRE TXN */}
                {
                    data?.txnRef && 
                    <>
                        <Typography variant='h4'>Previous Transaction Details <Typography variant='span'>(if refund)</Typography></Typography>
                        <Box sx={styles.box}>
                            <Box sx={styles.item}>
                                <Typography variant='h5_semibold' gutterBottom>Txn ID</Typography>
                                <Card sx={{ pl: 2, pr: 2  }}>{data.txnRef._id}</Card>
                            </Box>
                            <Box sx={styles.item}>
                                <Typography variant='h5_semibold' gutterBottom>Txn Type</Typography>
                                <Card sx={{ pl: 2, pr: 2  }}><Chip size='small' label={data.txnRef.txnType} color="primary" variant="filled" /></Card>
                            </Box>
                            <Box sx={styles.item}>
                                <Typography variant='h5_semibold' gutterBottom>Date and Time</Typography>
                                <Card sx={{ pl: 2, pr: 2  }}>{formatDateAndTimeTimeZone(data.txnRef.createdAt)}</Card>
                            </Box>
                            <Box sx={styles.item}>
                                <Typography variant='h5_semibold' gutterBottom>Amount</Typography>
                                <Card sx={{ pl: 2, pr: 2  }}>$ {data.txnRef.amount}</Card>
                            </Box>
                        </Box>
                    </>
                }
                {/* WITHDRAWAL */}
                {
                    data?.bank && 
                    <>
                        <Typography variant='h4'>Bank Details</Typography>
                        <Box sx={styles.box}>
                            <Box sx={styles.item}>
                                <Typography variant='h5_semibold' gutterBottom>Bank Name</Typography>
                                <Card sx={{ pl: 2, pr: 2  }}>{data.bank.bankName}</Card>
                            </Box>
                            <Box sx={styles.item}>
                                <Typography variant='h5_semibold' gutterBottom>Account Number</Typography>
                                <Card sx={{ pl: 2, pr: 2  }}>{data.bank.accNo}</Card>
                            </Box>
                            <Box sx={styles.item}>
                                <Typography variant='h5_semibold' gutterBottom>Account Holder</Typography>
                                <Card sx={{ pl: 2, pr: 2  }}>{data.bank.accHolderName}</Card>
                            </Box>
                            <Box sx={styles.item}>
                                <Typography variant='h5_semibold' gutterBottom>SWIFT Code</Typography>
                                <Card sx={{ pl: 2, pr: 2  }}>$ {data.bank.code}</Card>
                            </Box>
                        </Box>
                    </>
                }
            </DialogContent>
            {/* <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Save changes
                </Button>
            </DialogActions> */}
        </Dialog>
    )
}

export default TransactionOverview;