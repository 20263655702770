import { useQuery } from '@tanstack/react-query';
import api from '../config/api';

const getAdmins = async () => {
  const { data } = await api.get(`/admin/api/admin`);
  return data;
};

const useGetAdmins = () => {
  return useQuery({
    queryKey: ['admins'], 
    queryFn: getAdmins,
    refetchOnWindowFocus: false
  });
};

export default useGetAdmins;
