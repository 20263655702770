import React from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { formatNumber } from '../../utils';

const WalletStats = ({ data }) => {
  const walletStats = [
    {
      title: 'Total Money in Wallet',
      value: formatNumber(data?.totalBalance),
      backgroundColor: '#FDCC0A33',
    },
    {
      title: 'Average Money in Wallet',
      value: formatNumber(data?.averageMoneyInWallet),
      backgroundColor: '#FF9A3E33',
    },
    {
      title: 'Average Request Charge',
      value: formatNumber(data?.averageAverageReqCharge),
      backgroundColor: '#59D2B233',
    },
    {
      title: 'bunpun Revenue',
      value: formatNumber(data?.bunpunRevenue),
      backgroundColor: '#F2F2F2',
    },
  ];
  return (
    <Grid container gap={3}>
      {walletStats.map((stat, index) => (
        <Grid key={index} item xs={5.8} md>
          <Card
            sx={{
              textAlign: 'center',
              backgroundColor: stat.backgroundColor,
            }}
          >
            <Typography variant="h1_bold">
              $ {stat.value}
            </Typography>
            <Typography variant="h6" pt="5px">
              {stat.title}
            </Typography>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default WalletStats;
