import { useQuery } from '@tanstack/react-query';
import api from '../config/api';

const getCounts = async () => {
  const { data } = await api.get(`/admin/api/counts`);
  return data;
};

const useGetCounts = () => {
  return useQuery({
    queryKey: ['dashboard'],
    queryFn: getCounts
  });
};

export default useGetCounts;
