import React from 'react'
import { Stack, Box, Button } from '@mui/material';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import api from '../../config/api';

const AddAdmin = ({ showAddBtn }) => {
    const { handleSubmit, control } = useForm();
    const onSubmit = async (data) => {
        try {
            const { data: admin } = await api.post(`/admin/api/admin`, data);
            toast.success('Admin User Successfully');
            showAddBtn(true);
        } catch (error) {
            console.log(error);
        }
    }
  return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} direction={{ md: 'row', sm: 'column', xs: 'column' }} >
              <Box sx={{ flexGrow: 1 }}>
                <CustomTextField
                    name={'name'}
                    control={control}
                    label={'Name'}
                    rules={{ required: 'Please enter name' }}
                />
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <CustomTextField
                    name={'email'}
                    type={'email'}
                    control={control}
                    label={'email'}
                    rules={{ required: 'Please enter a valid email address' }}
                />
              </Box>
          </Stack>
          <Stack spacing={2} pt={2} direction={'row'} alignItems={'flex-start'} >
            <Button variant='outlined_light' onClick={() => showAddBtn(true)} >Cancel</Button>
            <Button variant='outlined_light' type={'submit'} >Save</Button>
          </Stack>
        </form>
  )
}

export default AddAdmin