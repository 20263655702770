import * as React from 'react';

function LogoIcon(props) {
  return (
    <svg width="48" 
    height="48" 
    viewBox="0 0 48 48" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    >
    <rect width="48" height="48" rx="11.6957" fill="url(#paint0_linear_3046_5493)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M18.4475 17.5245C18.4475 14.8202 20.6684 12.6012 23.4414 12.6012C26.2143 12.6012 28.4352 14.8202 28.4352 17.5245C28.4352 18.7376 27.9919 19.8467 27.2517 20.7071L29.6242 22.1132C30.7908 20.7574 31.2888 19.4494 31.2888 17.5245C31.2888 13.2146 27.7605 9.74756 23.4414 9.74756C19.1781 9.74756 15.6854 13.1254 15.5957 17.3576H15.5939V17.5245V22.559L15.5938 22.5961V32.751C15.5938 36.0926 19.2518 38.1455 22.1042 36.4046L30.4232 31.3272C33.1571 29.6585 33.1571 25.6885 30.4232 24.0199L22.1042 18.9424C20.9176 18.2182 19.5916 18.1505 18.4475 18.559L18.4475 17.5245ZM18.4475 24.016H18.4474L18.4474 32.751C18.4474 33.8648 19.6667 34.5492 20.6175 33.9689L28.9365 28.8914C29.8478 28.3352 29.8478 27.0118 28.9365 26.4556L20.6175 21.3782C19.6728 20.8016 18.463 21.4735 18.4475 22.5747V24.016Z" fill="#010101"/>
    <defs>
    <linearGradient id="paint0_linear_3046_5493" x1="-1.5" y1="1" x2="47" y2="47.5" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFF5CE"/>
    <stop offset="0.503757" stopColor="#FFEBD8"/>
    <stop offset="1" stopColor="#DEF6F0"/>
    </linearGradient>
    </defs>
  </svg>
  );
}

export default LogoIcon;
