import React from 'react'
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import UserWithBackground from '../../assets/icons/userwithbackground';
import useGetUser from '../../hooks/useGetUser';
import Loading from '../../components/Loading/Loading';
import { formatDate } from '../../utils';
import Button from '@mui/material/Button';

const Profile = ({ open, data, handleClose }) => {
    const navigate = useNavigate();
    const { data: user , isLoading } = useGetUser(data?.id);
    const gotoTransactions = () => {
        handleClose();
        navigate(`/users/${data?.id}/transactions`);
    }
    const styles = {
        box: {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          rowGap: '25px',
          columnGap: '16px',
          pt: 2, 
          pb: 6, 
        },
        item: {
          flexBasis: 'calc(33.333% - 16px)', // Adjust width to be 1/3 minus the gap
          boxSizing: 'border-box',
        }
      };
    if(isLoading) return <Loading />;
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth={true}
            maxWidth='md'
        >
            <DialogTitle sx={{ m: 0, p: 2, display: 'flex', alignItems: 'center', gap: 2 }} id="customized-dialog-title">
                <UserWithBackground size={35} />
                <Typography variant="h2">User Info</Typography>
                <Button variant="outlined" onClick={gotoTransactions} sx={{ ml: 3 }} > Transactions </Button>
            </DialogTitle>
            <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
            <DialogContent>
                <Typography variant='h4'>Personal Details</Typography>
                <Box sx={styles.box}>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Full Name</Typography>
                        <Card sx={{ pl: 2, pr: 2  }}>{user?.data?.name}</Card>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Sign Up Date</Typography>
                        <Card>{ user?.data?.createdAt && formatDate(user.data.createdAt) }</Card>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Phone Number</Typography>
                        <Box sx={{ display: 'flex', gap: 1}}>
                            <Card >{user?.data?.phone?.code || '-'}</Card>
                            <Card sx={{ flex: '1 auto' }} >{user?.data?.phone?.value || '-'}</Card>
                        </Box>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Email Address</Typography>
                        <Card>{user?.data?.email}</Card>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Address</Typography>
                        <Card>{user?.data?.profile?.address || '-'}</Card>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Country</Typography>
                        <Card>{user?.data?.country?.value || '-'}</Card>
                    </Box>
                </Box>
                <Typography variant='h4'>Bank & Wallet Details</Typography>
                <Box sx={styles.box}>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Account Number</Typography>
                        <Card sx={{ pl: 2, pr: 2  }}>{user?.data?.bank?.accNo  || '-'}</Card>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Bank Name</Typography>
                        <Card sx={{ pl: 2, pr: 2  }}>{user?.data?.bank?.bankName  || '-'}</Card>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Wallet Balance</Typography>
                        <Card sx={{ pl: 2, pr: 2  }}>{user?.data?.wallet?.totalBalance ?? 0}</Card>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Used Money</Typography>
                        <Card sx={{ pl: 2, pr: 2  }}>{user?.data?.spendMoney}</Card>
                    </Box>
                </Box>
                <Typography variant='h4'>Engagement</Typography>
                <Box sx={styles.box}>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Videos Created</Typography>
                        <Card sx={{ pl: 2, pr: 2  }}>{user?.data?.videosCreated}</Card>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Total Views</Typography>
                        <Card sx={{ pl: 2, pr: 2  }}>{user?.data?.totalViews}</Card>
                    </Box>
                    {/* <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Status</Typography>
                        <Card sx={{ pl: 2, pr: 2  }}>Active</Card>
                    </Box> */}
                </Box>
                <Typography variant='h4'>Gifts Received</Typography>
                <Box sx={styles.box}>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Flowers</Typography>
                        <Card sx={{ pl: 2, pr: 2  }}>{user?.data?.wallet?.gifts?.flowers ?? 0}</Card>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Hearts</Typography>
                        <Card sx={{ pl: 2, pr: 2  }}>{user?.data?.wallet?.gifts?.hearts ?? 0}</Card>
                    </Box>
                    <Box sx={styles.item}>
                        <Typography variant='h5_semibold' gutterBottom>Coins</Typography>
                        <Card sx={{ pl: 2, pr: 2  }}>{user?.data?.wallet?.gifts?.coins ?? 0}</Card>
                    </Box>
                </Box>
            </DialogContent>
            {/* <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Save changes
                </Button>
            </DialogActions> */}
        </Dialog>
    )
}

export default Profile;