import { useQuery } from '@tanstack/react-query';
import api from '../config/api';

const getUsers = async ({ queryKey }) => {
  const [_key, query] = queryKey;
  const { data } = await api.get(
    `/admin/api/user`,
    { params: query },
  );
  return data;
};

const useGetUsers = (query) => {
  return useQuery({
    queryKey: ['users', query], 
    queryFn: getUsers,
    enabled: !!query,
  });
};

export default useGetUsers;
