import React from 'react';
import { Stack } from '@mui/material';
import DashboardIcon from '../../assets/icons/DashboardIcon';
import WalletStats from './WalletStats';
import VideoStats from './VideoStats';
import StreamReqStats from './StreamReqStats';
import StreamStats from './StreamStats';
import TopStreamers from './TopStreamers';
import TopHeading from '../../components/TopHeading/TopHeading';
import useGetDashboard from '../../hooks/useGetDashboard';
import CustomDateFilter from '../../components/CustomDateFilter';
import Loading from '../../components/Loading/Loading';

function Dashboard() {
  const [date, setDate] = React.useState();
  const { data: dashboard, isLoading } = useGetDashboard(date);
  const [dateFilter, setSetDateFilter] = React.useState();
  const handleDateChange = (date) => setDate(date);

  if(isLoading) return <Loading />;
  return (
    <Stack spacing={3}>
      <TopHeading icon={<DashboardIcon />} title="Analytics Overview" />

      <WalletStats data={dashboard?.data?.walletStatistics} />
      <CustomDateFilter dateChange={handleDateChange} dateFilter={dateFilter} setSetDateFilter={setSetDateFilter} />
      <VideoStats data={dashboard?.data?.videoStatistics} userCount={dashboard?.data?.userCount} />

      <Stack direction="row" spacing={3}>
        <StreamReqStats data={dashboard?.data?.streamReqDurationsPercentage} />
        <TopStreamers data={dashboard?.data?.getTopStreamers} />
      </Stack>
      <StreamStats data={dashboard?.data} />
    </Stack>
  );
}

export default Dashboard;
