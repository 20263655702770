import React from 'react'
import { Stack, useTheme, Box } from '@mui/material';
import TopHeading from '../../components/TopHeading/TopHeading';
import CustomSearch from '../../components/CustomSearch';
import CustomDateFilter from '../../components/CustomDateFilter';
import DataTable from '../../components/Table';
import useGetTasks from '../../hooks/useGetTasks';
import Loading from '../../components/Loading/Loading';
import TableCell from '../../components/TableCell/TableCell';
import UserWithBackground from '../../assets/icons/userwithbackground';
import { formatDate } from '../../utils';
import TaskIcon from '../../assets/icons/TaskIcon';
import useGetCounts from '../../hooks/useGetCounts';
import CustomRating from '../../components/CustomRating/CustomRating';

export default function Tasks() {
  const theme = useTheme();
  const [filter, setFilter] = React.useState({ page: 1, size: 7, search: '' });
  const [dateFilter, setSetDateFilter] = React.useState();
  const { data: counts } = useGetCounts();
  const { data: tasks, isLoading } = useGetTasks(filter);

  const handleDateChange = async (newDate) => setFilter({ page: 1, size: 7, search: '', ...newDate });
  const handleSearch = (search) => {
    filter?.search !== search && setFilter({ ...filter, page: 1, size: 7, search });
  }
  const handlePagination = (page, size) => setFilter({ ...filter, page, size });
  const handleCellClick = (row) => {}

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'title',
      headerName: 'Title',
      width: 200,
    },
    {
      field: 'booingDate',
      headerName: 'Booking Date & Time',
      width: 150,
    },
    {
      field: 'requester',
      renderHeader: (params) => <TableCell icon={<UserWithBackground size={25} />} title='Requester Name' />,
      width: 180,
      renderCell: (params) => {
        const name = params.row.requester;
        return <TableCell icon={<UserWithBackground size={25} color={theme.palette.error.light} />} title={name} />;
      },
    },
    {
      field: 'streamer',
      renderHeader: (params) => <TableCell icon={<UserWithBackground size={25} />} title='Streamer Name' />,
      width: 180,
      renderCell: (params) => {
        const name = params.row.streamer || '-';
        return <TableCell icon={<UserWithBackground size={25} color={theme.palette.error.light} />} title={name} />;
      },
    },
    {
      field: 'amount',
      headerName: 'Booking Amount',
      width: 100,
      renderCell: (params) => {
        const amount = params.row.amount;
        return `$ ${amount}`;
      }
    },
    {
      field: 'duration',
      headerName: 'Stream Duration',
      width: 130,
      renderCell: (params) => {
        const duration = params.row.duration;
        return `${duration} minutes`;
      }
    },
    {
      field: 'requesterRating',
      headerName: 'Requester Rating',
      width: 200,
      display: 'flex',
      renderCell: (params) => {
        const rating = params?.row?.requesterRating?.rating;
        const comment = params?.row?.requesterRating?.comment;
        return <CustomRating style={{ overflow: 'hidden', textOverflow: 'ellipsis', }} rating={rating} comment={comment} />;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => {
        const task = params.row.status;
        return task?.streamStatus === 'STOPPED' ? 'Completed' : (task?.isDeleted ? 'Cancelled' : 'Pending');
      }
    },
  ];
  let rows = [];
  rows = tasks?.data?.data?.map((task) => ({
    id: task._id,
    booingDate: formatDate(task?.accepted?.proposeAt || task.requestAt),
    title: task.title,
    requester: task.user?.name,
    streamer: task?.accepted?.user?.name,
    amount: task?.accepted?.amount || task.amount,
    duration: task.duration,
    requesterRating: task?.requester,
    status: task,
  }));

  if (isLoading) return <Loading />;
  return (
    <Stack spacing={3}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TopHeading icon={<TaskIcon />} title={`Tasks (${ counts?.data?.taskCount || 0 })`} />
          <CustomSearch onSearch={handleSearch} value={filter?.search} />
        </Box>
        <CustomDateFilter dateChange={handleDateChange} dateFilter={dateFilter} setSetDateFilter={setSetDateFilter} />
        <DataTable 
          columns={columns} 
          rows={rows || []} 
          totalCount={tasks?.data?.totalCount || 0} 
          pagination={handlePagination} 
          pageSize={filter.size}
          page={filter.page}
          onCellClick={handleCellClick}
          rowHeight={55} 
        />
      </Stack>
  )
}
