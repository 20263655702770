import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CustomDropdown from '../../components/CustomDropdown/CustomDropdown';
import { useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import CustomTextArea from '../../components/CustomTextArea/CustomTextArea';
import api from '../../config/api';
import CircleIcon from '@mui/icons-material/Circle';
import { toast } from 'react-toastify';

const Status = ({ open, data, handleClose }) => {
    const { handleSubmit, control, setValue, reset } = useForm();

    useEffect(() => {
        setValue('status', data?.status || '')
        setValue('comment', data?.comment || '')
    }, [data])
    const handleOnClose = (update) => {
        reset();
        handleClose(update);
    }
    const handleOnChange = (data) => {}

    const onSubmit = async (value) => {
        console.log(value);
        try {
            const { data: withdrawal } = await api.put(`/admin/api/withdrawal/${data._id}`, {...value});
            toast.success('Updated Status Successfully');
            handleOnClose(true);
        } catch (error) {
            console.log(error);
        }
    }
    const indicator = (color) => <CircleIcon sx={{ width: 10, color }} />;
    const list = [
        { value: 'pending', text: 'Pending', icon: indicator((theme) => theme.palette.warning.dark) }, 
        { value: 'rejected', text: 'Rejected', icon: indicator((theme) => theme.palette.error.dark) },
        { value: 'completed', text: 'Completed', icon: indicator((theme) => theme.palette.success.dark) },
    ]
    return (
        <Dialog
            onClose={handleOnClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle id="customized-dialog-title">
            </DialogTitle>
            <IconButton
            aria-label="close"
            onClick={handleOnClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
            <DialogContent>
                <Typography variant='h4'>Status</Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={2} sx={{ mt: '1.5rem' }}>
                        <CustomDropdown 
                            name={'status'}
                            control={control}
                            rules={{ required: 'Status required' }}
                            list={list}
                            onChange={handleOnChange}
                        />
                        <CustomTextArea
                            name={'comment'}
                            label={'Comment box'}
                            control={control}
                            rules={{ required: 'Comment required' }}
                        />
                        <Stack spacing={2} direction={'row'} justifyContent={'center'}>
                            <Button variant={'text'} onClick={handleOnClose} >Cancel</Button>
                            <Button variant={'text'} type={'submit'}>Save</Button>
                        </Stack>
                    </Stack>
                </form>
            </DialogContent>
        </Dialog>
    );
}

export default Status;