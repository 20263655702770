import React from 'react'
import { Stack, Menu, Fade } from '@mui/material';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import CustomDateRangePicker from '../CustomDateRangePicker/CustomDateRangePicker';

const CustomDateFilter = ({ dateChange, dateFilter, setSetDateFilter }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleClose = () => {
    setAnchorEl(null);
    setSetDateFilter({});
    dateChange();
  }

  const activateBtn = (btn) => dateFilter?.btn === btn ? 'contained' : 'outlined';

  const today = () => {
    setSetDateFilter({ btn: 1 });
    dateChange({
      from: dayjs().startOf('day').toISOString(), 
      to: dayjs().endOf('day').toISOString()
    });
  }
  
  const lastWeek = () => {
    setSetDateFilter({ btn: 2 });
    dateChange({
      from: dayjs().subtract(1, 'week').startOf('week').toISOString(), 
      to: dayjs().subtract(1, 'week').endOf('week').toISOString(),
    });
  }
  
  const lastMonth = () => {
    setSetDateFilter({ btn: 3 });
    dateChange({ 
      from: dayjs().subtract(1, 'month').startOf('month').toISOString(),
      to: dayjs().subtract(1, 'month').endOf('month').toISOString() 
    });
  }
  
  const customDates = (event) => {
    setSetDateFilter({ btn: 4 });
    setAnchorEl(event.currentTarget);
  }

  const onDateChange = (dates) => {
    if (dates?.startDate && dates?.endDate) {
      setAnchorEl(null);
      setSetDateFilter({ btn: 4, ...dates });
      dateChange({ 
        from: dayjs(dates.startDate).startOf('day').toISOString(), 
        to: dayjs(dates.endDate).endOf('day').toISOString() 
      });
    }
  }

  return (
    <Stack direction="row" spacing={3}>
        <Button key={1} variant={activateBtn(1)} onClick={() => today()}>Today</Button>
        <Button key={2} variant={activateBtn(2)} onClick={() => lastWeek()}>Last Week</Button>
        <Button key={3} variant={activateBtn(3)} onClick={() => lastMonth()}>Last Month</Button>
        <Button key={4} variant={activateBtn(4)} onClick={customDates}
          id="fade-button"
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          From: { dateFilter?.startDate ? dateFilter.startDate.format('DD/MM/YYYY') : '__/__/____' }{'  '}
          To: { dateFilter?.endDate ? dateFilter.endDate.format('DD/MM/YYYY') : '__/__/____' }
        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{ 'aria-labelledby': 'fade-button' }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <CustomDateRangePicker onDateChange={onDateChange} selectedDate={dateFilter} />
      </Menu>
    </Stack>
  )
}

export default CustomDateFilter;