import React from 'react';
import { Card, Stack, Typography, useTheme } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import PieChart from '../../components/PieChart';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

const StreamStats = ({ data }) => {
  const theme = useTheme();
  const labels = ['Public Stream', 'Private Stream'];
  const streams = [
    {
      labels,
      datasets: [
        {
          label: 'Completed Streams',
          data: data?.completedTaskPercentage?.map(d => d.percentage),
          backgroundColor:  [
            theme.palette.success.dark,
            theme.palette.success.light,
          ],
          borderWidth: 1,
        },
      ],
      total: data?.completedTaskPercentage?.reduce((a, c) => a + c.count, 0),
    },
    {
      labels,
      datasets: [
        {
          label: 'Requested Streams',
          data: data?.RequestedTaskPercentage?.map(d => d.percentage),
          backgroundColor:  [
            theme.palette.warning.dark,
            theme.palette.warning.light,
          ],
          borderWidth: 1,
        },
      ],
      total: data?.RequestedTaskPercentage?.reduce((a, c) => a + c.count, 0),
    },
    {
      labels,
      datasets: [
        {
          label: 'Cancelled Streams',
          data: data?.CancelledTaskPercentage?.map(d => d.percentage),
          backgroundColor:  [
            theme.palette.error.dark,
            theme.palette.error.light,
          ],
          borderWidth: 1,
        },
      ],
      total: data?.CancelledTaskPercentage?.reduce((a, c) => a + c.count, 0),
    },
  ];

  return (
    <>
      <Stack direction="row" spacing={3}>
        {streams?.map((stream, index) => ( 
          <Card
            type="container"
            sx={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}
            key={index}
          >
            <Stack spacing={3} maxWidth="60%">
              <Card sx={{ width: 'fit-content' }}>{stream?.datasets[0]?.label}</Card>
              <PieChart data={stream} labels={labels} />
            </Stack>
            <Stack spacing={3}>
              <Typography variant="h1">
                {stream?.total}
                {/* <Typography variant="h6_dark" sx={{ color: '#00BA88' }}>
                  <ArrowDropUpIcon sx={{ mb: '-9px' }} />
                  2.45%
                </Typography> */}
              </Typography>
              <Card>
                <Stack spacing={3}>
                  <Stack alignItems="center">
                    <CircleIcon sx={{ color: stream?.datasets[0]?.backgroundColor[0] }} />
                    <Typography
                      variant="h6"
                      mt={1}
                      color={(theme) => theme.palette.text.light}
                    >
                      Public Streams
                    </Typography>
                    <Typography variant="h5">{stream?.datasets[0]?.data[0]}%</Typography>
                  </Stack>
                  <Stack alignItems="center">
                    <CircleIcon sx={{ color: stream?.datasets[0]?.backgroundColor[1] }} />
                    <Typography
                      variant="h6"
                      mt={1}
                      color={(theme) => theme.palette.text.light}
                    >
                      Private Streams
                    </Typography>
                    <Typography variant="h5" color="#010101">
                      {stream?.datasets[0]?.data[1]}%
                    </Typography>
                  </Stack>
                </Stack>
              </Card>
            </Stack>
          </Card>
        ))}
      </Stack>

      <Stack direction="row" spacing={3}>
        <Card
          sx={{
            flex: 1,
            position: 'relative',
            textAlign: 'center',
          }}
        >
          <ArrowCircleRightIcon
            sx={{
              color: '#59D2B2',
              position: 'absolute',
              top: '24px',
              right: '24px',
              rotate: '-45deg',
            }}
          />
          <Typography variant="h1_bold">{data?.streamStatistics?.publicStreams || 0}</Typography>
          <Typography variant="h6" pt="5px">
            Public Streams
          </Typography>
          {/* <Typography variant="p" fontSize="12px" color="#797979" pt="5px">
            12% Increase from Yesterday
          </Typography> */}
        </Card>
        <Card
          sx={{
            flex: 1,
            position: 'relative',
            textAlign: 'center',
          }}
        >
          <ArrowCircleRightIcon
            sx={{
              color: '#59D2B2',
              position: 'absolute',
              top: '20px',
              right: '20px',
              rotate: '-45deg',
            }}
          />
          <Typography variant="h1_bold">{data?.streamStatistics?.privateStreams || 0}</Typography>
          <Typography variant="h6" pt="5px">
            Private Streams
          </Typography>
          {/* <Typography variant="p" fontSize="12px" color="#797979" pt="5px">
            3% Decrease from Yesterday
          </Typography> */}
        </Card>
        <Card
          sx={{
            flex: 1,
            position: 'relative',
            textAlign: 'center',
          }}
        >
          <ArrowCircleRightIcon
            sx={{
              color: '#59D2B2',
              position: 'absolute',
              top: '20px',
              right: '20px',
              rotate: '-45deg',
            }}
          />
          <Typography variant="h1_bold">$ {data?.streamStatistics?.totalStreamingAmount || 0}</Typography>
          <Typography variant="h6" pt="5px">
            Total Streaming Amount
          </Typography>
          {/* <Typography variant="p" fontSize="12px" color="#797979" pt="5px">
            17% Increase from Yesterday
          </Typography> */}
        </Card>
      </Stack>
    </>
  );
};

export default StreamStats;
