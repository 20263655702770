import React from 'react';
import Loading from '../../components/Loading/Loading';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { GlobalImages } from '../../utils';
import { useForm } from 'react-hook-form';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../routes';
import api from '../../config/api';

export default function Login() {
  const theme = useTheme();
  const { handleSubmit, control } = useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const onSubmit = async (value) => {
    setIsLoading(true);
    const { email, password } = value;
    try {
      const { data } = await api.post('/admin/api/login', { email, password });
      sessionStorage.setItem('token', data.data.token);
      setIsLoading(false);
      navigate(ROUTES.DASHBOARD);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error.response?.data?.message || 'Login failed!');
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
          }}
        >
          <Box
            p={5}
            sx={{
              border: `1px solid ${theme.palette.border.main}`,
              borderRadius: '5px',
              minWidth: '500px',
            }}
          >
            <Box display={'flex'} justifyContent={'center'}>
              <img
                src={GlobalImages.BunpunLogo}
                alt="bunpun-logo"
                className="bp-logo"
              />
            </Box>
            <Stack spacing={1} alignItems={'center'} sx={{ mt: '2rem' }}>
              <Typography variant={'h3_dark'}>Hello Admin</Typography>
            </Stack>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={2} sx={{ mt: '1.5rem' }}>
                <CustomTextField
                  name={'email'}
                  label={'Email'}
                  control={control}
                  rules={{ required: 'Email is required' }}
                  type={'email'}
                />
                <CustomTextField
                  name={'password'}
                  label={'Password'}
                  control={control}
                  rules={{ required: 'Password is required' }}
                  type={'password'}
                />
                <Button variant={'contained'} type={'submit'}>
                  Log In
                </Button>
              </Stack>
            </form>
          </Box>
        </Box>
      )}
    </>
  );
}
