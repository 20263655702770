import { useQuery } from '@tanstack/react-query';
import api from '../config/api';

const getWithdrawals = async ({ queryKey }) => {
  const [_key, query] = queryKey;
  const { data } = await api.get(
    `/admin/api/withdrawal`,
    { params: query },
  );
  return data;
};

const useGetWithdrawals = (query) => {
  return useQuery({
    queryKey: ['withdrawals', query], 
    queryFn: getWithdrawals,
    enabled: !!query,
  });
};

export default useGetWithdrawals;
