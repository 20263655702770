import * as React from 'react';

function LogoutIcon(props) {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox={`0 0 ${props.size || 30} ${props.size || 30}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
        <path opacity="0.3" d="M10.7599 2C10.2799 2 9.8999 2.38 9.8999 2.86V21.15C9.8999 21.62 10.2799 22.01 10.7599 22.01C16.6499 22.01 20.7599 17.9 20.7599 12.01C20.7599 6.12 16.6399 2 10.7599 2Z" fill={props.variant === 'light' ? '#fff' : '#292D32' } />
        <path d="M15.5458 11.8929L15.5458 11.893L15.5525 11.8995C15.6492 11.9929 15.6492 12.137 15.5525 12.2305L15.5524 12.2304L15.5458 12.237L12.7064 15.0864C12.7064 15.0865 12.7063 15.0866 12.7062 15.0867C12.6549 15.1378 12.5944 15.16 12.53 15.16C12.4654 15.16 12.4048 15.1377 12.3535 15.0864C12.2588 14.9917 12.2588 14.8283 12.3535 14.7335L12.3547 14.7324L13.9147 13.1624L14.7617 12.31H13.56H3.98999C3.85613 12.31 3.73999 12.1938 3.73999 12.06C3.73999 11.9261 3.85613 11.81 3.98999 11.81H13.56H14.7671L13.9135 10.9564L12.3535 9.39642C12.2588 9.30168 12.2588 9.13826 12.3535 9.04353C12.4482 8.94886 12.6114 8.94879 12.7062 9.04331C12.7063 9.04338 12.7064 9.04345 12.7064 9.04353L15.5458 11.8929Z" fill={props.variant === 'light' ? '#292D32' : '#fff'} stroke="#FFC28B"/>
    </svg>
  );
}

export default LogoutIcon;
