import { useQuery } from '@tanstack/react-query';
import api from '../config/api';

const getDashboard = async ({ queryKey }) => {
  const [_key, date] = queryKey;
  const { data } = await api.get(
    `/admin/api/dashboard`,
    { params: date }
  );
  return data;
};

const useGetDashboard = (date) => {
  return useQuery({
    queryKey: ['dashboard', date],
    queryFn: getDashboard
  });
};

export default useGetDashboard;
