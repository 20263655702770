import * as React from 'react';

function EditIcon(props) {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox={`0 0 ${props.size || 30} ${props.size || 30}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
        <path opacity="0.4" d="M14.0996 14.667L2.09961 14.667C1.82628 14.667 1.59961 14.4403 1.59961 14.167C1.59961 13.8937 1.82628 13.667 2.09961 13.667L14.0996 13.667C14.3729 13.667 14.5996 13.8937 14.5996 14.167C14.5996 14.4403 14.3729 14.667 14.0996 14.667Z" fill={props.variant === 'light' ? '#fff' : '#010101'} />
        <path opacity="0.4" d="M12.7795 2.31994C11.4862 1.02661 10.2195 0.993275 8.89283 2.31994L8.08616 3.12661C8.0195 3.19328 7.99283 3.29994 8.0195 3.39328C8.52616 5.15994 9.9395 6.57328 11.7062 7.07994C11.7328 7.08661 11.7595 7.09328 11.7862 7.09328C11.8595 7.09328 11.9262 7.06661 11.9795 7.01328L12.7795 6.20661C13.4395 5.55328 13.7595 4.91994 13.7595 4.27994C13.7662 3.61994 13.4462 2.97994 12.7795 2.31994Z" fill={props.variant === 'light' ? '#fff' : '#010101'} />
        <path d="M10.5062 7.68654C10.3128 7.5932 10.1262 7.49987 9.94616 7.3932C9.7995 7.30654 9.6595 7.2132 9.5195 7.1132C9.40616 7.03987 9.27283 6.9332 9.14616 6.82654C9.13283 6.81987 9.08616 6.77987 9.03283 6.72654C8.81283 6.53987 8.56616 6.29987 8.34616 6.0332C8.32616 6.01987 8.29283 5.9732 8.24616 5.9132C8.1795 5.8332 8.06616 5.69987 7.96616 5.54654C7.88616 5.44654 7.79283 5.29987 7.70616 5.1532C7.5995 4.9732 7.50616 4.7932 7.41283 4.60654C7.3195 4.40654 7.24616 4.2132 7.1795 4.0332L2.99283 8.21987C2.90616 8.30654 2.82616 8.4732 2.80616 8.58654L2.44616 11.1399C2.3795 11.5932 2.50616 12.0199 2.78616 12.3065C3.02616 12.5399 3.3595 12.6665 3.7195 12.6665C3.7995 12.6665 3.8795 12.6599 3.9595 12.6465L6.5195 12.2865C6.6395 12.2665 6.80616 12.1865 6.88616 12.0999L11.0728 7.9132C10.8862 7.84654 10.7062 7.7732 10.5062 7.68654Z" fill={props.variant === 'light' ? '#fff' : '#292D32'} />

    </svg>
  );
}

export default EditIcon;
