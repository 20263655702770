import React from 'react'
import { Stack, Typography } from '@mui/material'
const TableCell = ({ icon, title, endAdornment }) => {
  return (
    <Stack
        direction="row"
        alignItems="center"
        gap={1}
    >
      {icon}
      <Typography variant='p' sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} title={title} >{title}</Typography>
      {endAdornment}
    </Stack>
  )
}

export default TableCell