import React from 'react'
import { Stack, Button } from '@mui/material';

const FilterButtons = ({ data, activeBtn, onBtnClick }) => {
    const activateBtn = (btn) => activeBtn === btn ? 'contained' : 'outlined';

    const click = (btn) => {
        onBtnClick(btn);
    }

    return (
        <Stack direction="row" spacing={3}>
            <Button variant={activateBtn('')} onClick={() => click('')}>All</Button>
            {data.map((btn, index) => (
                <Button key={index} variant={activateBtn(btn.value)} onClick={() => click(btn.value)}>{btn.text}</Button>
            ))}
        </Stack>
    )
}

export default FilterButtons