import * as React from 'react';
import { Navigate } from 'react-router-dom';
import ROUTES from '../../routes';
import Content from '../Content';
import SideMenu1 from '../SideMenu1';

export default function Layout() {
  const [open, setOpen] = React.useState(true);
  const getToken = sessionStorage.getItem('token');

  return getToken ? (
    <>
      <SideMenu1 open={open}  setOpen={setOpen} />
      <Content open={open} />
    </>
  ) : (
    <Navigate to={ROUTES.LOGIN} />
  );
}
