import { useQuery } from '@tanstack/react-query';
import api from '../config/api';

const getTransactions = async ({ queryKey }) => {
  const [_key, query] = queryKey;
  const { data } = await api.get(
    `/admin/api/transaction`,
    { params: query },
  );
  return data;
};

const useGetTransactions = (query) => {
  return useQuery({
    queryKey: ['transactions', query], 
    queryFn: getTransactions,
    enabled: !!query,
  });
};

export default useGetTransactions;
