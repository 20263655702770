import React from 'react';

const UserIcon = (props) => {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.912 9.713a.923.923 0 00-.262 0 3.592 3.592 0 01-3.475-3.6A3.614 3.614 0 0121.787 2.5 3.614 3.614 0 0125.4 6.112a3.612 3.612 0 01-3.488 3.6zM25.988 18.375c-1.4.937-3.363 1.287-5.175 1.05a8.164 8.164 0 00.737-3.363c0-1.25-.275-2.438-.8-3.475 1.85-.25 3.813.1 5.225 1.037 1.975 1.3 1.975 3.438.012 4.75zM8.05 9.713a.923.923 0 01.262 0 3.592 3.592 0 003.476-3.6A3.614 3.614 0 008.175 2.5a3.614 3.614 0 00-3.613 3.612 3.612 3.612 0 003.488 3.6zM8.188 16.062a8.14 8.14 0 00.737 3.4c-1.762.188-3.6-.187-4.95-1.075-1.975-1.312-1.975-3.45 0-4.762 1.338-.9 3.225-1.263 5-1.063a7.947 7.947 0 00-.787 3.5z"
        fill={'#9BE4D1'}
        fillOpacity={props.variant === 'light' ? 1 : 0.5}
      />
      <path
        d="M15.15 19.837a1.412 1.412 0 00-.325 0 4.281 4.281 0 01-4.137-4.287c0-2.375 1.912-4.3 4.3-4.3a4.3 4.3 0 014.3 4.3 4.273 4.273 0 01-4.138 4.287zM11.088 22.425c-1.888 1.262-1.888 3.337 0 4.587 2.15 1.438 5.675 1.438 7.825 0 1.887-1.262 1.887-3.337 0-4.587-2.138-1.438-5.663-1.438-7.825 0z"
        fill={props.variant === 'light' ? '#fff' : '#9BE4D1'}
      />
    </svg>
  );
};

export default UserIcon;
