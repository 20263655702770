import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

const getToken = async () => sessionStorage.getItem('token');

api.interceptors.request.use(
  async (config) => {
    const authToken = await getToken();
    if (authToken) config.headers.authorization = authToken;
    return config;
  },
  (error) => {
    sessionStorage.clear();
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) window.location.href = '/login';
    return Promise.reject(error);
  },
);

export default api;
