import React from 'react'
import { Card, Stack, Typography, Avatar, IconButton } from '@mui/material';
import UserWithBackground from '../../assets/icons/userwithbackground';
import CustomDropdown from '../../components/CustomDropdown/CustomDropdown';
import { useForm } from 'react-hook-form';
import api from '../../config/api';
import { toast } from 'react-toastify';
import PreviewImage from './PreviewImage';
import { formatDate } from '../../utils';
import { useTheme } from '@mui/material/styles';

const FeedbackCard = ({ list, data }) => {
    const { handleSubmit, control } = useForm();
    const [open, setOpen] = React.useState(false);
    const [url, setURL] = React.useState('');
    const theme = useTheme();

    const onSubmit = async (value) => {}
    const handleOnChange = async (status) => {
        try {
            const { data: feedback } = await api.put(`/admin/api/feedback/${data._id}`, {status});
            toast.success('Updated Status Successfully');
        } catch (error) {
            console.log(error);
        }
    }

    const handleClick = (url) => {
        setURL(url);
        setOpen(true);
    };

    return (
        <Card variant='outlined' sx={{ p: 2, height: '100%', boxShadow: '1px 1px 15px 1px #f5f4f2' }}>
            <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'} mb={2} >
                <Stack direction={'row'} alignItems={'center'} gap={2}>
                    <UserWithBackground size={55} />
                    <Stack direction={'column'}>
                        <Typography variant='h4' >{data?.user?.name}</Typography>
                        <Typography variant='h6' >{formatDate(data?.createdAt)}</Typography>
                    </Stack>
                </Stack>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CustomDropdown 
                        name={'status'}
                        control={control}
                        rules={{ required: 'Status required' }}
                        list={list}
                        defaultValue={data?.status || ''}
                        onChange={handleOnChange}
                    />
                </form>
            </Stack>
            <Stack direction={'column'} gap={1} mb={2} >
                <Typography variant='h5_bold' >{data?.title}</Typography>
                <Typography variant='p' sx={{ color: theme.palette.grey[600] }} >{data?.comment}</Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} >
                <Typography variant='h5_bold' sx={{ fontSize: 14, color: theme.palette.grey[600] }} >Attachments:</Typography>
                {data?.attachment?.map((_,index) => (
                    <IconButton onClick={() => handleClick(`${process.env.REACT_APP_S3_BUCKET}/${_}`)} key={index}>
                        <Avatar src={`${process.env.REACT_APP_S3_BUCKET}/${_}`} sx={{ width: 30, height: 30 }} variant="rounded" />
                    </IconButton>
                ))}
            </Stack>
            <PreviewImage url={url} open={open} setOpen={setOpen} />
        </Card>
    );
}

export default FeedbackCard