import * as React from 'react';

function StarIcon(props) {
  return (
    <svg
      width={props.size || 16}
      height={props.size || 16}
      viewBox={`0 0 ${props.size || 16} ${props.size || 16}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >        
        <path d="M3.8269 10.6667C3.90023 10.34 3.7669 9.87337 3.53357 9.64004L1.91356 8.02004C1.4069 7.51337 1.2069 6.97337 1.35356 6.50671C1.5069 6.04004 1.98023 5.72004 2.6869 5.60004L4.7669 5.25337C5.0669 5.20004 5.43356 4.93337 5.57356 4.66004L6.72023 2.36004C7.05357 1.70004 7.5069 1.33337 8.00023 1.33337C8.49357 1.33337 8.9469 1.70004 9.28023 2.36004L10.4269 4.66004C10.5136 4.83337 10.6936 5.00004 10.8869 5.11337L3.7069 12.2934C3.61356 12.3867 3.45356 12.3 3.48023 12.1667L3.8269 10.6667Z" fill={props.variant === 'light' ? '#01010130' : '#FDCC0A'} />
        <path d="M12.4667 9.64001C12.2267 9.88001 12.0934 10.34 12.1734 10.6667L12.6334 12.6733C12.8267 13.5067 12.7067 14.1333 12.2934 14.4333C12.1267 14.5533 11.9267 14.6133 11.6934 14.6133C11.3534 14.6133 10.9534 14.4867 10.5134 14.2267L8.56003 13.0667C8.25337 12.8867 7.7467 12.8867 7.44003 13.0667L5.4867 14.2267C4.7467 14.66 4.11337 14.7333 3.7067 14.4333C3.55337 14.32 3.44003 14.1667 3.3667 13.9667L11.4734 5.86001C11.78 5.55335 12.2134 5.41335 12.6334 5.48668L13.3067 5.60001C14.0134 5.72001 14.4867 6.04001 14.64 6.50668C14.7867 6.97335 14.5867 7.51335 14.08 8.02001L12.4667 9.64001Z" fill={props.variant === 'light' ? '#01010130' : '#FDCC0A'} />
    </svg>
  );
}

export default StarIcon;
