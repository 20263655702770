import React from 'react'
import { Stack, Typography } from '@mui/material';
import CustomTextField from '../CustomTextField/CustomTextField';

const CustomTextFieldWithLabel = ({ name, type, control, label, defaultValue, onChange, placeholder, rules }) => {
  return (
    <Stack spacing={1} sx={{ mt: '.5rem' }}>
        <Typography variant='h5_semibold'>{label}</Typography>
        <CustomTextField
            name={name}
            control={control}
            defaultValue={defaultValue}
            onChange={onChange}
            placeholder={placeholder}
            rules={rules}
            type={type}
        />
    </Stack>
  )
}

export default CustomTextFieldWithLabel;