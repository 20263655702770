import { useQuery } from '@tanstack/react-query';
import api from '../config/api';

const getSettings = async () => {
  const { data } = await api.get(`/admin/api/settings`);
  return data;
};

const useGetSettings = () => {
  return useQuery({
    queryKey: ['settings'], 
    queryFn: getSettings,
    refetchOnWindowFocus: false
  });
};

export default useGetSettings;
