import React from 'react'
import { Stack, Box, Button, Grid } from '@mui/material';
import TopHeading from '../../components/TopHeading/TopHeading';
import FeedbackIcon from '../../assets/icons/FeedbackIcon';
import CustomSearch from '../../components/CustomSearch';
import FilterButtons from './FilterButtons';
import useGetFeedbacks from '../../hooks/useGetFeedback';
import useReducedResult from '../../hooks/useReduceResult';
import FeedbackCard from './FeedbackCard';
import Loading from '../../components/Loading/Loading';
import useGetCounts from '../../hooks/useGetCounts';

const Feedback = () => {
  const [filter, setFilter] = React.useState({ size: 20, search: '' });
  const [activeBtn, setActiveBtn] = React.useState('');
  const { data: counts } = useGetCounts();
  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useGetFeedbacks(filter);
  
  const loadMore = () => fetchNextPage();
  const handleSearch = (search) => {
    filter?.search !== search && setFilter({ ...filter, search });
  }
  const handleBtnClick = (status) => {
    setActiveBtn(status);
    setFilter({ ...filter, search: '', status });
  };
  
  const list = [
    { value: 'Open', text: 'Open' },
    { value: 'In-Queue', text: 'In-Queue' },
    { value: 'Resolved', text: 'Resolved' },
    { value: 'Consider Later', text: 'Consider Later' },
  ]
  const feedbacks = useReducedResult(data);

  if (isLoading) return <Loading />;
  return (
    <Stack spacing={3}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
          <TopHeading icon={<FeedbackIcon />} title={`Feedback (${ counts?.data?.feedbackCount || 0 })`} />
          <CustomSearch onSearch={handleSearch} value={filter?.search} />
      </Box>
      <FilterButtons data={list} activeBtn={activeBtn} onBtnClick={handleBtnClick}/>
      <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }} >
           {feedbacks?.map((_, index) => (
            <Grid item  xs={2} sm={4} md={4} key={index}>
                <FeedbackCard 
                  list={list}
                  data={_}
                />
             </Grid>
          ))}
      </Grid>
      { (feedbacks?.length > 20 && hasNextPage && !isFetchingNextPage) && <Button onClick={loadMore} >Load more...</Button> }
    </Stack>
  )
}

export default Feedback