import * as React from 'react';

function DashboardIcon(props) {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.875 13.625v-8.5c0-1.875-.8-2.625-2.788-2.625h-5.05c-1.987 0-2.787.75-2.787 2.625v8.5c0 1.875.8 2.625 2.788 2.625h5.05c1.987 0 2.787-.75 2.787-2.625zM13.75 16.375v8.5c0 1.875-.8 2.625-2.787 2.625h-5.05c-1.988 0-2.788-.75-2.788-2.625v-8.5c0-1.875.8-2.625 2.787-2.625h5.05c1.988 0 2.788.75 2.788 2.625z"
        fill={props.variant === 'light' ? '#fff' : '#010101'}
      />
      <path
        opacity={0.4}
        d="M26.875 24.875v-3.5c0-1.875-.8-2.625-2.788-2.625h-5.05c-1.987 0-2.787.75-2.787 2.625v3.5c0 1.875.8 2.625 2.788 2.625h5.05c1.987 0 2.787-.75 2.787-2.625zM13.75 8.625v-3.5c0-1.875-.8-2.625-2.787-2.625h-5.05c-1.988 0-2.788.75-2.788 2.625v3.5c0 1.875.8 2.625 2.787 2.625h5.05c1.988 0 2.788-.75 2.788-2.625z"
        fill={props.variant === 'light' ? '#fff' : '#010101'}
      />
    </svg>
  );
}

export default DashboardIcon;
