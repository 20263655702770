import { useQuery } from '@tanstack/react-query';
import api from '../config/api';

const getUser = async ({ queryKey }) => {
  const [_key, useId] = queryKey;
  const { data } = await api.get(
    `/admin/api/user/${useId}`,
  );
  return data;
};

const useGetUser = (useId) => {
  return useQuery({
    queryKey: ['user', useId], 
    queryFn: getUser,
    enabled: !!useId
  });
};

export default useGetUser;
