import * as React from 'react';

function TransactionIcon(props) {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path opacity="0.6" d="M7.81 2H16.18C19.83 2 22 4.17 22 7.81V16.18C22 19.82 19.83 21.99 16.19 21.99H7.81C4.17 22 2 19.83 2 16.19V7.81C2 4.17 4.17 2 7.81 2Z" fill={props.variant === 'light' ? '#fff' : '#FFC28B'}/>
      <path d="M17.69 13.48C17.61 13.3 17.47 13.15 17.28 13.07C17.19 13.03 17.09 13.01 16.99 13.01H7C6.59 13.01 6.25 13.35 6.25 13.76C6.25 14.17 6.59 14.51 7 14.51H15.19L13.52 16.18C13.23 16.47 13.23 16.95 13.52 17.24C13.67 17.39 13.86 17.46 14.05 17.46C14.24 17.46 14.43 17.39 14.58 17.24L17.53 14.29C17.6 14.22 17.65 14.14 17.69 14.05C17.77 13.86 17.77 13.66 17.69 13.48Z" fill={props.variant === 'light' ? '#fff' : '#FFC28B'}/>
      <path d="M6.31 10.52C6.39 10.7 6.53 10.85 6.72 10.93C6.81 10.97 6.9 10.99 7 10.99H17C17.41 10.99 17.75 10.65 17.75 10.24C17.75 9.82998 17.41 9.48998 17 9.48998H8.81L10.48 7.81998C10.77 7.52998 10.77 7.04998 10.48 6.75998C10.19 6.46998 9.71 6.46998 9.42 6.75998L6.47 9.70998C6.4 9.77998 6.35 9.85998 6.31 9.94998C6.23 10.14 6.23 10.34 6.31 10.52Z" fill={props.variant === 'light' ? '#fff' : '#FFC28B'}/>
    </svg>
  );
}

export default TransactionIcon;
