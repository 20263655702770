import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import api from '../../config/api';
import TopHeading from '../../components/TopHeading/TopHeading';
import UserWithBackground from '../../assets/icons/userwithbackground';
import CustomDropdownWithLabel from '../../components/CustomDropdownWithLabel/CustomDropdownWithLabel';
import CustomTextFieldWithLabel from '../../components/CustomTextFieldWithLabel/CustomTextFieldWithLabel';
import DocumentUploadIcon from '../../assets/icons/DocumentUploadIcon';
import CancelIcon from '../../assets/icons/CancelIcon';
import resizeImage from '../../utils/resizeImage';
import CustomDatePicker from '../../components/CustomDatePicker/CustomDatePicker';
import dayjs from 'dayjs';
import CustomInputAndDropdown from '../../components/CustomInputAndDropdown/CustomInputAndDropdown';

function Referral({ open, data, isEdit, settings, handleClose }) {
  const theme = useTheme();
  const { handleSubmit, control, setValue, reset, watch } = useForm();
  const [files, setFiles] = React.useState([]);
  const [resizedFiles, setResizedFiles] = React.useState([]);
  const [fileError, setFileError] = React.useState(false);

  const handleOnClose = (update) => {
    reset();
    handleClose(update);
  };
  const handleOnChange = (data) => {
    console.log(data);
  };

  const handleSelectImageClick = () => {
    document.getElementById('screenShot').click();
    setFileError(false);
  };

  const handleImageSelect = async (e) => {
    console.log(e.target.files);
    setFiles(Object.values(e.target.files));

    const modifiedFiles = await Promise.all(
      Object.values(e.target.files).map(
        async (file) => await resizeImage(file),
      ),
    );
    setResizedFiles(modifiedFiles);
  };

  const reward = React.useMemo(
    () =>
      settings?.data?.rewards?.map((reward, i) => ({
        text: `Free ${reward.value} ${reward.type.charAt(0) + reward.type.slice(1).toLowerCase()}`,
        value: i,
        data: reward,
      })),
    [settings],
  );
  const events = [
    { text: 'Upon Sign up', value: 'SIGN_UP' },
    { text: 'Upon Creating the First Video', value: 'CREATE_1ST_VIDEO' },
    { text: 'Upon Booking the First Stream', value: 'BOOK_1ST_STREAM' },
  ];

  const list = ['MIN', 'MONEY'];

  useEffect(() => {
    if (isEdit) {
      setValue('name', data.name);
      setValue('startDate', dayjs(data.startDate));
      setValue('endDate', dayjs(data.endDate));
      setValue('description', data.description);
      setValue('event', data.event);
      setValue('limit', data.limit);
      setFiles(data.attachment);
      // const [referee] = reward?.filter(
      //   (r) =>
      //     r.data.type === data.rewardReferee.type &&
      //     r.data.value === data.rewardReferee.value,
      // );
      setValue('rewardReferee', data.rewardReferee);
      // const [referral] = reward?.filter(
      //   (r) =>
      //     r.data.type === data.rewardReferral.type &&
      //     r.data.value === data.rewardReferral.value,
      // );
      setValue('rewardReferral', data.rewardReferral);
    }
  }, [isEdit, data]);

  const onSubmit = async (value) => {
    console.log({value});
    value.startDate = value.startDate.toISOString();
    value.endDate = value.endDate.toISOString();

    if (files.length < 1) {
      setFileError(true);
      return;
    }

    const formData = new FormData();
    resizedFiles.forEach((file) => {
      file?.name && formData.append('file', file); // if file name is exists upload file;
    });

    // value.rewardReferee = JSON.stringify(reward[value.rewardReferee].data);
    // value.rewardReferral = JSON.stringify(reward[value.rewardReferral].data);
    value.rewardReferee = JSON.stringify(value.rewardReferee);
    value.rewardReferral = JSON.stringify(value.rewardReferral);
    Object.keys(value).forEach((key) => formData.append(key, value[key]));

    try {
      const { data: referral } = await api.post(
        `/admin/api/referral/${data?._id}`,
        formData,
      );
      toast.success(`${isEdit ? 'Updated' : 'Created'} Referral Successfully!`);
      handleOnClose(true);
    } catch (error) {
        toast.error(
            (error?.response?.status === 400) ? 
            error?.response?.data?.message :
            `${isEdit ? 'Updated' : 'Created'} Referral Failed!`
        );
        console.log(error);
    }
  };

  console.log(settings?.data);
  return (
    <Dialog
      onClose={handleOnClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="customized-dialog-title" sx={{ maxWidth: '90%' }}>
        <TopHeading
          icon={<UserWithBackground color={theme.palette.warning.light} />}
          title={`${isEdit ? 'Update' : 'Create'}  Referral`}
        />
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleOnClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={6}>
            <Stack spacing={2}>
              <Typography variant="h4">Referral Details</Typography>
              <Stack
                spacing={2}
                direction={{ md: 'row', sm: 'column', xs: 'column' }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <CustomTextFieldWithLabel
                    name={'name'}
                    control={control}
                    label={'Referral Name'}
                    rules={{ required: 'Referral Name is required' }}
                    // onChange={(e) => handleOnChange(input.name, e.target.value)}
                  />
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <CustomDatePicker
                    name={'startDate'}
                    control={control}
                    label={'Start Date'}
                    rules={{ required: 'Start Date is required' }}
                    max={watch('endDate')}
                  />
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <CustomDatePicker
                    name={'endDate'}
                    control={control}
                    label={'End Date'}
                    rules={{ required: 'End Date is required' }}
                    min={watch('startDate')}
                  />
                </Box>
              </Stack>
              <CustomTextFieldWithLabel
                name={'description'}
                control={control}
                label={'Description (Rules & guidelines via text)'}
                rules={{ required: 'Description is required!' }}
              />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="h4">Reward</Typography>
              <Stack
                spacing={2}
                direction={{ md: 'row', sm: 'column', xs: 'column' }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  {/* <CustomDropdownWithLabel
                    name={'rewardReferee'}
                    control={control}
                    rules={{ required: 'Reward for referee is required!' }}
                    label={'Reward for Referee'}
                    list={reward || []}
                    defaultValue={0}
                    onChange={handleOnChange}
                  /> */}
                  <CustomInputAndDropdown
                    name={'rewardReferee'} 
                    control={control}
                    rules={{ required: 'Reward for referee is required!' }}
                    label={'Reward for Referee'}
                    placeholder={'value'}
                    list={list || []}
                    defaultValue={{ input: 0, dropdown: list[0] }}
                    onChange={handleOnChange}
                  />
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  {/* <CustomDropdownWithLabel
                    name={'rewardReferral'}
                    control={control}
                    rules={{ required: 'Reward for referral is required!' }}
                    label={'Reward for Referral'}
                    list={reward || []}
                    defaultValue={1}
                    onChange={handleOnChange}
                  /> */}
                  <CustomInputAndDropdown
                    name={'rewardReferral'} 
                    control={control}
                    rules={{ required: 'Reward for referral is required!' }}
                    label={'Reward for Referral'}
                    placeholder={'value'}
                    list={list || []}
                    defaultValue={{ input: 0, dropdown: list[0] }}
                    onChange={handleOnChange}
                  />
                </Box>
              </Stack>
            </Stack>
            <Stack spacing={2}>
              <Typography variant="h4">Setting</Typography>
              <Stack
                spacing={2}
                direction={{ md: 'row', sm: 'column', xs: 'column' }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <CustomDropdownWithLabel
                    name={'event'}
                    control={control}
                    rules={{ required: 'Event is required!' }}
                    label={'Set Event for successful referral'}
                    list={events || []}
                    defaultValue={events[0].value}
                    onChange={handleOnChange}
                  />
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <CustomTextFieldWithLabel
                    name={'limit'}
                    control={control}
                    rules={{
                      required: 'limit is required',
                      pattern: {
                        value: /^[0-9]*$/,
                        message: 'Only numbers are allowed',
                      },
                    }}
                    label={'Referral users limit'}
                    placeholder={'only first X users'}
                  />
                </Box>
              </Stack>
            </Stack>
            <Stack spacing={2}>
              <Typography variant="h4">Attachments</Typography>
              <Stack spacing={1}>
                <Box
                  sx={{
                    padding: '14px 20px',
                    borderRadius: '8px',
                    border: `1px solid ${fileError ? theme.palette.error.main : 'rgba(0, 0, 0, 0.1)'}`,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    {files.map((file, i) => (
                      <Box sx={{ position: 'relative' }} key={file?.name || i}>
                        <img
                          src={
                            file?.name
                            ? URL.createObjectURL(file)
                            : `${process.env.REACT_APP_S3_BUCKET}/${file}`
                          }
                          alt={file?.name || file}
                          width={30}
                          height={30}
                        />
                        <Box
                          sx={{
                            position: 'absolute',
                            top: -8,
                            right: -8,
                            zIndex: 99,
                            backgroundColor: '#fff',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '50%',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            isEdit
                              ? setFiles(files.filter((url) => url !== file))
                              : setFiles(
                                  files.filter(
                                    (originalFile) =>
                                      originalFile?.name !== file?.name,
                                  ),
                                );
                          }}
                        >
                          <CancelIcon size={16} />
                        </Box>
                      </Box>
                    ))}
                  </Stack>
                  <IconButton onClick={handleSelectImageClick}>
                    <DocumentUploadIcon />
                  </IconButton>
                </Box>
                <input
                  id="screenShot"
                  type="file"
                  hidden
                  onChange={(e) => handleImageSelect(e)}
                  accept="image/*"
                  multiple={false}
                />
                {fileError && (
                  <Typography
                    variant="span"
                    sx={{ fontSize: '9px', color: theme.palette.error.main }}
                  >
                    Banner is required!
                  </Typography>
                )}
              </Stack>
              <Stack
                spacing={1}
                sx={{ pb: 3 }}
                direction={'row'}
                justifyContent={'center'}
              >
                <Button variant={'outlined'} type={'submit'}>
                  {isEdit ? 'Update' : 'Save'}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default Referral;
