import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

export default function DataTable({ columns, rows, pagination, onCellClick, totalCount, page, pageSize, rowHeight }) {
  const handlePaginationChange = (model) => pagination(model.page + 1, model.pageSize);

  return (
    <Box sx={{ height: 485, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: page - 1, pageSize },
          },
        }}
        pageSizeOptions={[7, 15, 20]}
        checkboxSelection
        disableRowSelectionOnClick
        pagination
        paginationMode="server"
        rowCount={totalCount}
        onPaginationModelChange={handlePaginationChange}
        onCellClick={onCellClick}
        rowHeight={rowHeight}
      />
    </Box>
  );
}
