import * as React from 'react';

function TaskIcon(props) {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.625 8.75v10H7.937a3.569 3.569 0 00-3.562 3.563V8.75c0-5 1.25-6.25 6.25-6.25h8.75c5 0 6.25 1.25 6.25 6.25z"
        fill={'#FFC28B'}
        fillOpacity={props.variant === 'light' ? 1 : 0.5}
      />
      <path
        d="M25.625 18.75v4.375A4.38 4.38 0 0121.25 27.5H8.75a4.38 4.38 0 01-4.375-4.375v-.813c0-1.962 1.6-3.562 3.563-3.562h17.687zM20 9.688H10a.944.944 0 01-.938-.938c0-.512.426-.938.938-.938h10c.512 0 .938.426.938.938a.944.944 0 01-.938.938zM16.25 14.063H10a.944.944 0 01-.938-.938c0-.512.426-.938.938-.938h6.25c.512 0 .938.426.938.938a.944.944 0 01-.938.938z"
        fill={props.variant === 'light' ? '#fff' : '#FFC28B'}
      />
    </svg>
  );
}

export default TaskIcon;
