import { useQuery } from '@tanstack/react-query';
import api from '../config/api';

const getUsersTransactions = async ({ queryKey }) => {
  const [_key, query] = queryKey;
  const { data } = await api.get(
    `/admin/api/user/${query.id}/transactions`,
    { params: query },
  );
  return data;
};

const useGetUsersTransactions = (query) => {
  return useQuery({
    queryKey: ['users-transactions', query], 
    queryFn: getUsersTransactions,
    enabled: !!query,
    retryOnMount: true,
  });
};

export default useGetUsersTransactions;
