import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

const CustomTextField = ({
  name,
  control,
  label,
  type = 'text',
  rules,
  defaultValue = '',
  onChange = () => {},
  placeholder,
  startAdornment,
  endAdornment,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({
        field,
        fieldState: { error },
        formState,
      }) => (
        <TextField
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          onChange={(e) => {
            field.onChange(e);
            onChange(e);
          }}
          value={field.value}
          fullWidth
          sx={{
            mb: 1,
            '& input': {
              padding: '12px',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(242, 242, 242, 1)',
              },
              '&:hover fieldset': {
                borderColor: 'rgba(242, 242, 242, 1)',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'rgba(242, 242, 242, 1)',
              },
            },
          }}
          InputProps={{
            style: { },
            startAdornment,
            endAdornment,
          }}
          variant="outlined"
          placeholder={placeholder || label}
          type={type}
        />
      )}
    />
  );
};

export default CustomTextField;
