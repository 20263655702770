import React from 'react';
import { Stack, useTheme } from '@mui/material';
import TopHeading from '../../components/TopHeading/TopHeading';
import UserIcon from '../../assets/icons/UserIcon';
import useGetUsers from '../../hooks/useGetUsers';
import DataTable from '../../components/Table';
import { formatDate } from '../../utils';
import CustomDateFilter from '../../components/CustomDateFilter';
import CustomSearch from '../../components/CustomSearch';
import { Box } from '@mui/material';
import Loading from '../../components/Loading/Loading';
import Profile from './Profile';
import TableCell from '../../components/TableCell/TableCell';
import UserWithBackground from '../../assets/icons/userwithbackground';
import useGetCounts from '../../hooks/useGetCounts';

export default function Users() {
  const theme = useTheme();
  const [filter, setFilter] = React.useState({ page: 1, size: 7, search: '' });
  const [dateFilter, setSetDateFilter] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = React.useState({});
  const { data: counts } = useGetCounts();
  const { data: users, isLoading } = useGetUsers(filter);

  const handleDateChange = async (newDate) => setFilter({ page: 1, size: 7, search: '', ...newDate });
  const handleSearch = (search) => {
    filter?.search !== search && setFilter({ ...filter, page: 1, size: 7, search });
  }
  const handlePagination = (page, size) => setFilter({ ...filter, page, size });
  const handleCellClick = (row) => {
    setRow(row);
    setOpen(true);
  }
  const handleClose = () => {
    setRow({});
    setOpen(false);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      renderHeader: (params) => <TableCell icon={<UserWithBackground size={25} />} title='Name' />,
      width: 200,
      renderCell: (params) => {
        const name = params.row.name;
        return <TableCell icon={<UserWithBackground size={25} color={theme.palette.success.light} />} title={name} />;
      },
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 250,
    },
    {
      field: 'balance',
      headerName: 'Wallet Balance',
      width: 110,
      renderCell: (params) => `$ ${params.row.balance}`,
    },
    {
      field: 'createdAt',
      headerName: 'Sign up Date',
      width: 160,
    },
    {
      field: 'fullName',
      headerName: 'Rating',
      width: 160,
    },
    {
      field: 'videosCreated',
      headerName: 'Videos Created',
      width: 100,
    },
    {
      field: 'totalViews',
      headerName: 'Total Views',
      width: 100,
    },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   width: 100,
    // },
  ];
  let rows = [];
  rows = users?.data?.data?.map((user) => ({
    id: user.user._id,
    name: user.user.name,
    location: user.user?.profile?.address || user.user?.country?.value,
    balance: user.walletBalance,
    createdAt: formatDate(user.user.createdAt),
    videosCreated: user.videosCreated,
    totalViews: user.totalViews,
    // status: user.status || 'Active',
  }));
  if (isLoading) return <Loading />;
  return (
      <Stack spacing={3}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TopHeading icon={<UserIcon />} title={`Users (${ counts?.data?.userCount || 0 })`} />
          <CustomSearch onSearch={handleSearch} value={filter?.search} />
        </Box>
        <CustomDateFilter dateChange={handleDateChange} dateFilter={dateFilter} setSetDateFilter={setSetDateFilter} />
        <DataTable 
        columns={columns} 
        rows={rows || []} 
        totalCount={users?.data?.totalCount || 0} 
        pagination={handlePagination} 
        pageSize={filter.size}
        page={filter.page}
        onCellClick={handleCellClick} 
        />
        <Profile open={open} data={row} handleClose={handleClose} />
      </Stack>
  );
}
