import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import DonutChart from '../../components/DonutChart';

const StreamReqStats = ({ data }) => {
  const labels = data?.map(l => `${l.duration} min`);
  const value = data?.map(l => l.percentage)
  const backgroundColor = ['#010101', '#888888', '#cccccc', '#eeeeee'];
  return (
    <Card
      type="container"
      sx={{
        flex: 1,
      }}
    >
      <Card sx={{ width: 'fit-content' }}>Streaming Requests</Card>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '20px 22% 20px 22%',
        }}
      >
        <DonutChart 
          label='Streaming Requests'
          labels={labels}
          value={value}
          backgroundColor={backgroundColor}
         />
      </Box>
      <Card
        sx={{
          display: 'flex',
        }}
      >
        { labels?.map((l, i) => (
          <Box
            key={i}
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="p" sx={{ fontSize: '12px' }}>
              <CircleIcon sx={{ color: backgroundColor[i], height: '10px' }} />
              {l}
            </Typography>
            <Typography variant="h4" color="#010101">
              {value[i]}%
            </Typography>
          </Box>
        ))}
      </Card>
    </Card>
  );
};

export default StreamReqStats;
