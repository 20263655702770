import React from 'react';
import { Controller } from 'react-hook-form';
import { Select, MenuItem, InputBase, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomInput = styled(InputBase)({
    border: '1px solid #F2F2F2',
    borderRadius: '12px',
    minWidth: "150px",
    color: "#010101",
    height: "50px",
    padding: '10px 10px 10px 20px',
});

const CustomDropdown = ({
    name,
    control,
    rules,
    list,
    onChange,
    defaultValue,
    label,
}) => { 

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field }) => (
            <Select
                {...field}
                onChange={(e) => {
                    field.onChange(e);
                    onChange(e.target.value);
                }}
                value={field.value}
                input={<CustomInput />}
                variant='outlined'
                placeholder={label}
            >
                { list.map((item, index) => (<MenuItem
                 key={item.value ?? item} 
                 value={item.value ?? item}
                 >  
                    <Stack spacing={2} direction={'row'} justifyContent={'left'}>
                        {item.icon}
                        <Typography variant='span'>{item.text ?? item}</Typography>
                    </Stack>
                </MenuItem>)) }
            </Select>
            )}
        />
    );
}

export default CustomDropdown;