import * as React from 'react';

function FeedbackIcon(props) {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.85 12.2v3.924c0 .25-.013.5-.038.738-.225 2.65-1.787 3.975-4.662 3.975h-.387a.753.753 0 00-.626.312l-1.175 1.575c-.524.7-1.362.7-1.887 0L12.9 21.15a.917.917 0 00-.625-.312h-.388c-3.137 0-4.7-.775-4.7-4.7v-3.925c0-2.875 1.325-4.438 3.975-4.663.238-.025.488-.037.738-.037h6.287c3.088-.013 4.663 1.562 4.663 4.687z"
        fill={props.variant === 'light' ? '#fff' : '#292D32'}
      />
      <path
        opacity={0.4}
        d="M20.237 2.5H9.774c-4.55 0-7.262 2.713-7.262 7.262v10.463c0 4.55 2.712 7.262 7.262 7.262h10.463c4.55 0 7.262-2.712 7.262-7.262V9.762c0-4.55-2.712-7.262-7.262-7.262z"
        fill={props.variant === 'light' ? '#fff' : '#292D32'}
      />
    </svg>
  );
}

export default FeedbackIcon;
