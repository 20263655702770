import React from 'react';
import { Stack, Typography } from '@mui/material';
import CustomDropdown from '../CustomDropdown/CustomDropdown';

const CustomDropdownWithLabel = ({
  name,
  control,
  label,
  rules,
  list,
  defaultValue,
  onChange,
}) => {
  return (
    <Stack spacing={1} sx={{ mt: '.5rem' }}>
      <Typography variant="h5_semibold">{label}</Typography>
      <CustomDropdown
        name={name}
        control={control}
        rules={rules}
        list={list}
        onChange={onChange}
        defaultValue={defaultValue}
      />
    </Stack>
  );
};

export default CustomDropdownWithLabel;
