import React from 'react';
import { Stack, useTheme, Box } from '@mui/material';
import TopHeading from '../../components/TopHeading/TopHeading';
import CustomSearch from '../../components/CustomSearch';
import CustomDateFilter from '../../components/CustomDateFilter';
import DataTable from '../../components/Table';
import useGetWithdrawals from '../../hooks/useGetWithdrawals';
import Loading from '../../components/Loading/Loading';
import TableCell from '../../components/TableCell/TableCell';
import UserWithBackground from '../../assets/icons/userwithbackground';
import { formatDateAndTime } from '../../utils';
import WithdrawalIcon from '../../assets/icons/WithdrawalIcon';
import CircleIcon from '@mui/icons-material/Circle';
import NotificationIcon from '../../assets/icons/notification';
import Status from './Status';
import useGetCounts from '../../hooks/useGetCounts';

const Withdrawals = () => {
  const theme = useTheme();
  const [filter, setFilter] = React.useState({ page: 1, size: 7, search: '' });
  const [dateFilter, setSetDateFilter] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = React.useState({});
  const { data: counts } = useGetCounts();
  const { data: withdrawals, isLoading, refetch } = useGetWithdrawals(filter);

  const handleDateChange = async (newDate) => setFilter({ page: 1, size: 7, search: '', ...newDate });
  const handleSearch = (search) => {
    filter?.search !== search && setFilter({ ...filter, page: 1, size: 7, search });
  }
  const handlePagination = (page, size) => setFilter({ ...filter, page, size });
  const handleCellClick = (row) => { console.log({row})
    if (row.field === 'status') {
      setOpen(true);
      setRow(row.value);
    }
  }
  const handleClose = (update) => {
    setRow({});
    setOpen(false);
    update === true && refetch();
  };
  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      renderHeader: (params) => <TableCell icon={<UserWithBackground size={25} />} title='User Name' />,
      width: 220,
      renderCell: (params) => {
        const name = params.row.name;
        return <TableCell icon={<UserWithBackground size={25} color={theme.palette.warning.light} />} title={name} />;
      },
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 200,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      width: 100,
      renderCell: (params) => {
        const amount = params.row.amount;
        return `$ ${amount}`;
      }
    },
    {
      field: 'requestDate',
      headerName: 'Request Date/Time',
      width: 180,
    },
    {
      field: 'accNo',
      headerName: 'Bank Account',
      width: 170,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => { 
        const status = params.row.status.status;
        const color = status === 'completed' ? theme.palette.success.dark : (status === 'rejected' ? theme.palette.error.dark : theme.palette.warning.dark);
        return (
          <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
            <TableCell icon={<CircleIcon sx={{ width: 10, color }} />} title={status} />
            <NotificationIcon size={25} />
          </Stack>
        );
      }
    },
  ];
  let rows = [];
  rows = withdrawals?.data?.data?.map((withdrawal) => ({
    id: withdrawal._id,
    name: withdrawal.user.name,
    location: withdrawal.user.profile.address ?? withdrawal.user.country.value,
    amount: withdrawal.amount,
    requestDate: formatDateAndTime(withdrawal.createdAt),
    accNo: withdrawal.bank.accNo,
    comment: withdrawal.comment,
    status: withdrawal,
  }));

  if (isLoading) return <Loading />;
  return (
    <Stack spacing={3}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TopHeading icon={<WithdrawalIcon size={40} />} title={`Withdrawal Request (${ counts?.data?.withdrawalCount || 0 })`} />
          <CustomSearch onSearch={handleSearch} value={filter?.search} />
        </Box>
        <CustomDateFilter dateChange={handleDateChange} dateFilter={dateFilter} setSetDateFilter={setSetDateFilter} />
        <DataTable 
          columns={columns} 
          rows={rows || []} 
          totalCount={withdrawals?.data?.totalCount || 0} 
          pagination={handlePagination} 
          pageSize={filter.size}
          page={filter.page}
          onCellClick={handleCellClick} 
        />
        <Status open={open} data={row} handleClose={handleClose} />
    </Stack>
  )
}

export default Withdrawals