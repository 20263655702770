import React from 'react';
import { Stack, Box, Chip, useTheme } from '@mui/material';
import TopHeading from '../../components/TopHeading/TopHeading';
import CustomSearch from '../../components/CustomSearch';
import CustomDateFilter from '../../components/CustomDateFilter';
import DataTable from '../../components/Table';
import Loading from '../../components/Loading/Loading';
import { formatDateAndTime } from '../../utils';
import TransactionIcon from '../../assets/icons/TransactionIcon';
import useGetCounts from '../../hooks/useGetCounts';
import useGetTransactions from '../../hooks/useGetTRansactions';
import TransactionOverview from './TransactionOverview';
import TableCell from '../../components/TableCell/TableCell';
import UserWithBackground from '../../assets/icons/userwithbackground';

const Transactions = () => {
    const theme = useTheme();
    const [filter, setFilter] = React.useState({ page: 1, size: 7, search: '' });
    const [dateFilter, setSetDateFilter] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [row, setRow] = React.useState({});
    const { data: counts } = useGetCounts();
    const { data: transactions, isLoading } = useGetTransactions(filter);

    const handleDateChange = async (newDate) => setFilter({ page: 1, size: 7, search: '', ...newDate });
    const handleSearch = (search) => {
        filter?.search !== search && setFilter({ page: 1, size: 7, search });
        setSetDateFilter(undefined);
    }
    const handlePagination = (page, size) => setFilter({ ...filter, page, size });
    const handleCellClick = (row) => {
        setOpen(true);
        setRow(transactions?.data?.data?.find((txn) => txn._id === row.id));
    }
    const handleClose = () => {
        setRow({});
        setOpen(false);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 130 },
        {
            field: 'name',
            renderHeader: (params) => <TableCell icon={<UserWithBackground size={25} />} title='Name' />,
            width: 200,
            renderCell: (params) => {
              const name = params.row.name;
              return <TableCell icon={<UserWithBackground size={25} color={theme.palette.success.light} />} title={name} />;
            },
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 200,
            renderCell: (params) => <Chip label={params.row.type} color="primary" variant="filled" />,
        },
        {
            field: 'txnType',
            headerName: 'Transaction Type',
            width: 100,
            renderCell: (params) => <Chip label={params.row.txnType} color="secondary" variant="filled" />,
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            width: 180,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 70,
            renderCell: (params) => `$ ${params.row.amount}`,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                const status = params.row.status;
                return status ? <Chip label={status?.toUpperCase()} color={
                    (status === 'succeeded' || status === 'completed') ? 'success' :
                        (status === 'rejected' || status === 'canceled') ? 'error' : 'info'} variant="filled" /> : '';
            },
        },
        {
            field: 'taskId',
            headerName: 'Stream ID',
            width: 100,
        },
        {
            field: 'txnRef',
            headerName: 'Previous Transaction ID',
            width: 100,
        },
        {
            field: 'bank',
            headerName: 'Withdrawal Bank',
            width: 100,
        },
    ];
    let rows = [];
    rows = transactions?.data?.data?.map((txn) => ({
        id: txn._id,
        name: txn?.user?.name || '-',
        type: txn.type,
        txnType: txn.txnType,
        createdAt: formatDateAndTime(txn.createdAt),
        amount: txn.amount,
        status: txn.status,
        taskId: txn?.task?._id,
        txnRef: txn?.txnRef?._id,
        bank: txn?.bank?.bankName,
    }));

    if (isLoading) return <Loading />;
    return (
        <Stack spacing={3}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <TopHeading icon={<TransactionIcon size={40} />} title={`Transactions (${counts?.data?.transactionCount || 0})`} />
                <CustomSearch onSearch={handleSearch} placeholder={'Search Txn id'} value={filter?.search} />
            </Box>
            <CustomDateFilter dateChange={handleDateChange} dateFilter={dateFilter} setSetDateFilter={setSetDateFilter} />
            <DataTable
                columns={columns}
                rows={rows || []}
                totalCount={transactions?.data?.totalCount || 0}
                pagination={handlePagination}
                pageSize={filter.size}
                page={filter.page}
                onCellClick={handleCellClick} 
            />
            <TransactionOverview open={open} handleClose={handleClose} data={row} />
        </Stack>
    )
}

export default Transactions;