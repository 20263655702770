import React from 'react'
import { Dialog, DialogTitle, IconButton, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const PreviewImage = ({ open, url, setOpen }) => {
  return (
    <Dialog
            onClose={() => setOpen(false)}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle id="customized-dialog-title">
            </DialogTitle>
            <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
            <DialogContent>
                <img src={url} alt="preview" style={{ width: '100%' }} />
            </DialogContent>
    </Dialog>
  )
}

export default PreviewImage