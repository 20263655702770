import { useInfiniteQuery } from '@tanstack/react-query';
import api from '../config/api';

const getFeedbacks = async ({ queryKey, pageParam  }) => {
  const [_key, query] = queryKey;
  const { data } = await api.get(
    `/admin/api/feedback`,
    { params: { ...query, page: pageParam } },
  );
  return {...data, previousId: pageParam, nextId: data?.data?.data?.length > 0 ? pageParam+1 : undefined }; // previousId, nextId for next page
};

const useGetFeedbacks = (query) => {
  return useInfiniteQuery({
    queryKey: ['feedbacks', query], 
    queryFn: getFeedbacks,
    initialPageParam: 1,
    getPreviousPageParam: (firstPage) => firstPage.previousId ?? undefined,
    getNextPageParam: (lastPage) => lastPage.nextId ?? undefined,
    enabled: !!query,
  });
};

export default useGetFeedbacks;
