import { useQuery } from '@tanstack/react-query';
import api from '../config/api';

const getTasks = async ({ queryKey }) => {
  const [_key, query] = queryKey;
  const { data } = await api.get(
    `/admin/api/task`,
    { params: query },
  );
  return data;
};

const useGetTasks = (query) => {
  return useQuery({
    queryKey: ['tasks', query], 
    queryFn: getTasks,
    enabled: !!query,
  });
};

export default useGetTasks;
