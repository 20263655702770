import React from 'react';
import { Stack, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';
/**
 * 
 * @onDateChange callback function
 * @selectedDate is object with the following properties {startDate, endDate }
 * 
 * **/
const CustomDateRangePicker = ({ onDateChange, selectedDate }) => {
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();

  const handleStartDateChange = (e) => {
    setStartDate(e);
    onDateChange({ 
      startDate: e, 
      endDate: endDate ?? undefined,
    });
  }
  const handleEndDateChange = (e) => {
    setEndDate(e);
    onDateChange({ 
      startDate: startDate ?? undefined, 
      endDate:  e
    });
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack direction={'row'} spacing={3}>
        <Stack direction={'column'}>
          <Typography variant='h4' pl={3} pt={3} >Start Date</Typography>
          <StaticDatePicker
            defaultValue={selectedDate?.startDate}
            maxDate={endDate || dayjs()}  
            sx={{
                '& .MuiPickersLayout-actionBar' : {
                  display: 'none'
                },
                '& .MuiDatePickerToolbar-root' : {
                  display: 'none'
                }
            }}
            onChange={handleStartDateChange}
          />
        </Stack>
        <Stack direction={'column'}>
          <Typography variant='h4' pl={3} pt={3} >End Date</Typography>
          <StaticDatePicker
            defaultValue={selectedDate?.endDate}   
            minDate={startDate}
            maxDate={dayjs()}
            sx={{
                '& .MuiPickersLayout-actionBar' : {
                  display: 'none'
                },
                '& .MuiDatePickerToolbar-root' : {
                  display: 'none'
                }
            }}
            disableHighlightToday={true}
            onChange={handleEndDateChange}
          />
        </Stack>
      </Stack>
    </LocalizationProvider>
  )
}

export default CustomDateRangePicker