
const ROUTES = {
    LOGIN: '/login',
    DASHBOARD: '/dashboard',
    USERS: '/users',
    USER_TRANSACTIONS: '/users/:id/transactions',
    TASKS: '/tasks',
    WITHDRAWALS: '/withdrawals',
    TRANSACTIONS: '/transactions',
    FEEDBACKS: '/feedbacks',
    SETTINGS: '/settings',
    REFERRALS: '/referrals',
}

export default ROUTES;