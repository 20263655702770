import { Stack, Typography } from '@mui/material'
import React from 'react'
import StarIcon from '../../assets/icons/Star'

const CustomRating = ({ rating, comment }) => {
    return (
        <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} title={comment} >
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} >
                { rating >= 0 ? Array.from({ length: 5 }, (_, index) => (
                        <StarIcon key={index} variant={rating >= index + 1 ? '' : 'light'} />
                    )) : ''}
            </Stack>
            { comment && <Typography sx={{ fontSize: 12, pt: 1, textAlign: 'left' }}>{comment.substring(0, 30)}{comment.length > 30 && '...'}</Typography> }
        </Stack>
    )
}

export default CustomRating