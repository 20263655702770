import { useQuery } from '@tanstack/react-query';
import api from '../config/api';

const getAdminProfile = async () => {
  const { data } = await api.get(`/admin/api/admin-user`);
  return data;
};

const useGetAdminProfile = () => {
  return useQuery({
    queryKey: ['profile'], 
    queryFn: getAdminProfile,
    refetchOnWindowFocus: false
  });
};

export default useGetAdminProfile;
