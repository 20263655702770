import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const Loading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '93vw',
      }}
    >
      <CircularProgress sx={{
        color: (theme) => theme.palette.warning.dark
      }} />
    </Box>
  );
};

export default Loading;
