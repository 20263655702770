import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: { main: '#010101' },
    success: { dark: '#59D2B280', main: '#00BA88', light: '#59D2B233' },
    error: { dark: '#FF9A3E80', main: '#FF706C', light: '#FF9A3E33' },
    warning: { dark: '#FDCC0A80', main: '#FF9A3E', light: '#FDCC0A33' },
    dark: { main: '#364043', light: '#f6f6f6' },
    text: { main: '#111111CC', light: '#797979' },
    border: { main: '#F2F2F2' },
  },
  shape: { borderRadius: 8 },
  typography: {
    fontFamily: ['Urbanist', 'sans-serif'].join(','),
    h1: {
      fontSize: '34px',
      fontWeight: 700,
      lineHeight: '42px',
      letterSpacing: '0.17px',
    },
    h1_bold: {
      fontSize: '48px',
      fontWeight: 500,
      lineHeight: '57.6px',
      letterSpacing: '0.17px',
    },
    h2: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '33.6px',
      letterSpacing: '0.17px',
    },
    h3_dark: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '28px',
      letterSpacing: '0.17px',
    },
    h3_bold: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '28px',
      letterSpacing: '0.17px',
    },
    h4: {
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '21.6px',
      letterSpacing: '0.17px',
    },
    h5_dark: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '21px',
      letterSpacing: '0.17px',
    },
    h5_bold: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '21px',
      letterSpacing: '0.17px',
    },
    h5_semibold: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '0.17px',
    },
    h5: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '21px',
      letterSpacing: '0.17px',
    },
    h6_dark: {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '20px',
      letterSpacing: '0.17px',
    },
    h6_bold: {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '0.17px',
    },
    h6_semibold: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.17px',
    },
    h6: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.17px',
    },
    caption_bold: {
      fontSize: '9px',
      fontWeight: 600,
      lineHeight: '14px',
      letterSpacing: '0.17px',
    },
    caption: {
      fontSize: '9px',
      fontWeight: 400,
      lineHeight: '14px',
      letterSpacing: '0.17px',
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            color: '#000',
          },
        },
        {
          props: { variant: 'outlined_light' },
          style: {
            border: '1px solid rgba(242, 242, 242, 1)'
          },
        },
      ],
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '24px',
          letterSpacing: '0.17px',
          borderImageWidth: '2px',
          textTransform: 'none',
          boxShadow: 'none',
          borderRadius: '12px',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
      variants: [
        {
          props: { type: 'container' },
          style: {
            padding: '24px',
            borderRadius: '24px',
          },
        },
      ],
      styleOverrides: {
        root: {
          border: '1px solid #F2F2F2',
          padding: '8px 16px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '20px !important',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: '24px',
          height: '24px',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          '& .MuiBottomNavigationAction-label': {
            fontSize: '9px',
            fontWeight: 500,
            lineHeight: '14px',
            letterSpacing: '0.17px',
          },
          '& .MuiBottomNavigationAction-label.Mui-selected': {
            fontSize: '9px',
          },
        },
      },
    },
    MuiSelect: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            color: '#000',
          },
        },
      ],
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '24px',
          letterSpacing: '0.17px',
          borderImageWidth: '2px',
          textTransform: 'none',
          boxShadow: 'none',
          borderRadius: '12px',
        },
      },
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            color: '#000',
          },
        },
      ],
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '19px',
          letterSpacing: '0.17px',
          borderImageWidth: '2px',
          textTransform: 'none',
          boxShadow: 'none',
          borderRadius: '12px'
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'filled' },
          style: {
            color: 'white',
          },
        },
      ],
      styleOverrides: {
        root: {
          fontSize: '10px',
          lineHeight: '18px',
        },
      },
    },
  },
});
export default theme;
