import * as React from 'react';

function SettingIcon(props) {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.4}
        d="M2.5 16.1v-2.2c0-1.3 1.063-2.374 2.375-2.374 2.263 0 3.188-1.6 2.05-3.563A2.374 2.374 0 017.8 4.726l2.163-1.238c.987-.587 2.262-.237 2.85.75l.137.238c1.125 1.962 2.975 1.962 4.113 0l.137-.238c.588-.987 1.863-1.337 2.85-.75l2.162 1.238a2.374 2.374 0 01.875 3.237c-1.137 1.963-.212 3.563 2.05 3.563 1.3 0 2.375 1.062 2.375 2.375v2.2c0 1.3-1.062 2.375-2.375 2.375-2.262 0-3.187 1.6-2.05 3.562a2.37 2.37 0 01-.875 3.238l-2.162 1.237c-.988.588-2.262.238-2.85-.75l-.137-.237c-1.125-1.963-2.975-1.963-4.113 0l-.137.237c-.588.988-1.863 1.338-2.85.75L7.8 25.276a2.374 2.374 0 01-.875-3.238c1.138-1.962.213-3.562-2.05-3.562A2.382 2.382 0 012.5 16.1z"
        fill={props.variant === 'light' ? '#fff' : '#010101'}
      />
      <path
        d="M15 19.063a4.062 4.062 0 100-8.125 4.062 4.062 0 000 8.124z"
        fill={props.variant === 'light' ? '#fff' : '#010101'}
      />
    </svg>
  );
}

export default SettingIcon;
