import React from 'react';
import { Stack, Box, Chip } from '@mui/material';
import TopHeading from '../../components/TopHeading/TopHeading';
import UserIcon from '../../assets/icons/UserIcon';
import DataTable from '../../components/Table';
import { formatDateAndTimeTimeZone } from '../../utils';
import Loading from '../../components/Loading/Loading';
import useGetUsersTransactions from '../../hooks/useUserTransactions';
import { useParams } from 'react-router-dom';
import CustomDateFilter from '../../components/CustomDateFilter';
import useGetUser from '../../hooks/useGetUser';
import TransactionOverview from '../Transactions/TransactionOverview';

const UserTransactions = () => {
    const { id } = useParams();
    const { data: user } = useGetUser(id);
    const [filter, setFilter] = React.useState({ page: 1, size: 7, id });
    const [dateFilter, setSetDateFilter] = React.useState();
    const [open, setOpen] = React.useState(false);
    const [row, setRow] = React.useState({});
    const { data: transactions, isLoading } = useGetUsersTransactions(filter);

    const handlePagination = (page, size) => setFilter({ ...filter, page, size });
    const handleDateChange = async (newDate) => setFilter({ page: 1, size: 7, id, ...newDate });

    const handleClose = () => {
        setRow({});
        setOpen(false);
    };
    const handleCellClick = (row) => {
        setOpen(true);
        setRow({ ...transactions?.data?.data?.find((txn) => txn._id === row.id), user: user.data });
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'type',
            headerName: 'Type',
            width: 150,
            renderCell: (params) => <Chip label={params.row.type} color="primary" variant="filled" />,
        },
        {
            field: 'txnType',
            headerName: 'Transaction Type',
            width: 100,
            renderCell: (params) => <Chip label={params.row.txnType} color="secondary" variant="filled" />,
        },
        {
            field: 'createdAt',
            headerName: 'Date',
            width: 200,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: 100,
            renderCell: (params) => `$ ${params.row.amount}`,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                const status = params.row.status;
                return status ? <Chip label={status?.toUpperCase()} color={ 
                    (status === 'succeeded' || status === 'completed') ? 'success' :
                    (status === 'rejected' || status === 'canceled') ? 'error' : 'info' } variant="filled" /> : '';
            },
        },
        {
            field: 'taskId',
            headerName: 'Stream ID',
            width: 100,
        },
        {
            field: 'taskType',
            headerName: 'Stream Type',
            width: 100,
            renderCell: (params) => params.row.taskType ? <Chip label={params.row.taskType} color="primary" variant="outlined" /> : '',
        },
        {
            field: 'taskTitle',
            headerName: 'Stream Title',
            width: 150,
        },
        {
            field: 'txnRef',
            headerName: 'Previous Transaction ID',
            width: 90,
        },
        {
            field: 'bank',
            headerName: 'Withdrawal Bank',
            width: 150,
        },
    ];
    let rows = [];
    rows = transactions?.data?.data?.map((txn) => ({
        id: txn._id,
        type: txn.type,
        txnType: txn.txnType,
        createdAt: formatDateAndTimeTimeZone(txn.createdAt),
        amount: txn.amount,
        status: txn.status,
        taskId: txn?.task?._id,
        taskType: txn?.task?.type,
        taskTitle: txn?.task?.title,
        txnRef: txn?.txnRef?._id,
        bank: txn?.bank?.bankName,
    }));
    if (isLoading) return <Loading />;
    return (
        <Stack spacing={3}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <TopHeading
                    icon={<UserIcon />}
                    title={`${user?.data?.name ?? 'User'} - Transactions (${transactions?.data?.totalCount || 0})`}
                />
            </Box>
            <CustomDateFilter dateChange={handleDateChange} dateFilter={dateFilter} setSetDateFilter={setSetDateFilter} />
            <DataTable
                columns={columns}
                rows={rows || []}
                totalCount={transactions?.data?.totalCount || 0}
                pagination={handlePagination}
                pageSize={filter.size}
                page={filter.page}
                onCellClick={handleCellClick}
            />
            <TransactionOverview open={open} handleClose={handleClose} data={row} />
        </Stack>
    );
};

export default UserTransactions;
