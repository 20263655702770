import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import UserWithBackground from '../../assets/icons/userwithbackground';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VideocamIcon from '@mui/icons-material/Videocam';

const TopStreamers = ({ data }) => {
  return (
    <Card type="container" sx={{ flex: 1 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Card sx={{ width: 'fit-content' }}>Top Streamers</Card>
        <Typography
          variant="p"
          sx={{
            border: '1px solid #F2F2F2',
            borderRadius: '12px',
            p: '10px 20px',
            fontSize: '14px',
          }}
        >
          No. of Views
        </Typography>
      </Box>
      { data?.map((user, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            border: '1px solid #F2F2F2',
            borderRadius: '12px',
            p: '10px 20px',
            gap: '20px',
            mt: '20px',
            mb: '20px',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              gap: '15px',
            }}
          >
            <UserWithBackground />
            <Typography variant="h5" sx={{ fontSize: '14px' }}>
              {user?.user?.name}
            </Typography>
          </Box>
          <Box sx={{ width: '50px', display: 'flex', alignItems: 'left', gap: '5px' }}>
            <RemoveRedEyeIcon sx={{ width: '15px' }} />
            <Typography variant="h5" sx={{ fontSize: '14px' }}>
              {user?.totalViews}
            </Typography>
          </Box>
          <Box sx={{ width: '50px', display: 'flex', alignItems: 'left', gap: '5px' }}>
            <VideocamIcon sx={{ width: '15px' }} />
            <Typography variant="h5" sx={{ fontSize: '14px' }}>
              {user?.totalCount}
            </Typography>
          </Box>
        </Box>
      ))}
    </Card>
  );
};

export default TopStreamers;
