import { Navigate, Route, Routes } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainer } from 'react-toastify';
import ROUTES from './routes';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard/Dashboard';
import Users from './pages/Users/Users';
import Tasks from './pages/Tasks/Tasks';
import Settings from './pages/Settings/Settings';
import Login from './pages/Login/Login';
import Withdrawals from './pages/Withdrawals/Withdrawals';
import Feedback from './pages/Feedback/Feedback';
import Referrals from './pages/Referrals/Referrals';
import UserTransactions from './pages/Users/UserTransactions';
import Transactions from './pages/Transactions/Transactions';

function App() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Routes>
        {/* PUBLIC ROUTES START */}
        <Route path="/" element={<Navigate to={ROUTES.DASHBOARD} replace />} />
        <Route path={ROUTES.LOGIN} element={<Login />} />
        {/* PRIVATE ROUTES START */}
        <Route element={<Layout />}>
          <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route path={ROUTES.USERS} element={<Users />} />
          <Route path={ROUTES.USER_TRANSACTIONS} element={<UserTransactions />} />
          <Route path={ROUTES.TASKS} element={<Tasks />} />
          <Route path={ROUTES.WITHDRAWALS} element={<Withdrawals />} />
          <Route path={ROUTES.TRANSACTIONS} element={<Transactions />} />
          <Route path={ROUTES.FEEDBACKS} element={<Feedback />} />
          <Route path={ROUTES.SETTINGS} element={<Settings />} />
          <Route path={ROUTES.REFERRALS} element={<Referrals />} />
        </Route>
        {/* Other routes go here */}
        <Route path="*" element={<Login />} />
      </Routes>
      <ToastContainer position="top-right" autoClose={2000} />
    </Box>
  );
}

export default App;
