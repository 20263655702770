import * as React from 'react';

function ReferralIcon(props) {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
    <path d="M16.6249 10.1375L18.275 13.4375C18.5 13.8875 19.0999 14.3375 19.5999 14.4125L22.5875 14.9125C24.5 15.2375 24.9499 16.6125 23.5749 17.9875L21.25 20.3125C20.8625 20.7 20.6374 21.4625 20.7624 22.0125L21.425 24.9C21.95 27.175 20.7375 28.0625 18.725 26.875L15.9249 25.2125C15.4124 24.9125 14.5875 24.9125 14.075 25.2125L11.2749 26.875C9.26244 28.0625 8.04994 27.175 8.57494 24.9L9.23748 22.0125C9.36248 21.475 9.13746 20.7125 8.74996 20.3125L6.42498 17.9875C5.04998 16.6125 5.49995 15.225 7.41245 14.9125L10.4 14.4125C10.9 14.325 11.5 13.8875 11.725 13.4375L13.375 10.1375C14.2625 8.35 15.7374 8.35 16.6249 10.1375Z" fill={props.variant === 'light' ? '#fff' : '#292D32'} />
    <path opacity={0.4} d="M7.5 12.1875C6.9875 12.1875 6.5625 11.7625 6.5625 11.25V2.5C6.5625 1.9875 6.9875 1.5625 7.5 1.5625C8.0125 1.5625 8.4375 1.9875 8.4375 2.5V11.25C8.4375 11.7625 8.0125 12.1875 7.5 12.1875Z" fill={props.variant === 'light' ? '#fff' : '#292D32'} />
    <path opacity={0.4} d="M22.5 12.1875C21.9875 12.1875 21.5625 11.7625 21.5625 11.25V2.5C21.5625 1.9875 21.9875 1.5625 22.5 1.5625C23.0125 1.5625 23.4375 1.9875 23.4375 2.5V11.25C23.4375 11.7625 23.0125 12.1875 22.5 12.1875Z" fill={props.variant === 'light' ? '#fff' : '#292D32'} />
    <path opacity={0.4} d="M15 5.9375C14.4875 5.9375 14.0625 5.5125 14.0625 5V2.5C14.0625 1.9875 14.4875 1.5625 15 1.5625C15.5125 1.5625 15.9375 1.9875 15.9375 2.5V5C15.9375 5.5125 15.5125 5.9375 15 5.9375Z" fill={props.variant === 'light' ? '#fff' : '#292D32'} />
    </svg>
  );
}

export default ReferralIcon;
